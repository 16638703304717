import {Injectable}   from '@angular/core';
import {FactorsStore} from '../../stores/common/factors.store';
import IUnit = Data.Common.IUnit;

export interface IFactorService {
  calculate(value: number, source: IUnit | string, target: IUnit | string): number;
}

@Injectable({
  providedIn: 'root'
})
export class FactorService implements IFactorService {
  constructor(private factorStore: FactorsStore) {
  }

  public calculate(value: number, source: IUnit | string, target: IUnit | string): number {
    const factor = this.factorStore.getFactor(source, target);
    return Math.round(value * factor.Factor * 100) / 100;
  }
}
