import {AfterContentInit, Component, ElementRef, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, of}                    from 'rxjs';
import {NgScrollbar}                                        from 'ngx-scrollbar';
import {MapStore}                                           from '../../../stores/map/map.store';
import {debounceTime, map}                                  from 'rxjs/operators';
import {ListSortOrder, StringFactory}                       from 'ts-tooling';
import {TranslationStore}                                   from '../../../stores/translation/translation.store';
import {ISelectionChangeEvent}                              from './ap-legend-scroller-item.component';
import OlStyle                                              from 'ol/style/Style';
import OlStyleStroke                                        from 'ol/style/Stroke';
import {ApLegendScrollerService}                            from './ap-legend-scroller.service';
import OlIcon                                               from 'ol/style/Icon';
import IconAnchorUnits                                      from 'ol/style/IconAnchorUnits';
import {ILegend}                                            from '../../../stores/map/map.legend.store';
import {LEGEND_IDS}                                         from 'src/app/ap-interface/enums/ap-legend-ids.enum';

@Component({
  selector: 'ap-legend-scroller',
  template: `
    <div class="ap-legend-scroller">
      <button class="ap-legend-scroll-button"
              *ngIf="contentScrollable | async"
              (click)="moveLeft()">
        <span class="k-icon k-i-arrow-chevron-left"></span>
        <span class="k-icon k-i-arrow-chevron-left"></span>
      </button>

      <div #scrollableView class="ap-legend-scroller-scrollable-view"
           [style.width]="(legends$ | async)?.length * 155 + 'px'">
        <ng-scrollbar #scrollBar
                      [track]="'horizontal'"
                      [visibility]="'always'"
                      [sensorDebounce]="100"
                      [autoHeightDisabled]="false"
                      (updated)="updateScrollbar()">
          <div #scrollerContent class="ap-legend-scroller-content">
            <ng-container *ngFor="let l of (legends$ | async)">
              <ap-legend-scroller-item [item]="l"></ap-legend-scroller-item>
            </ng-container>
          </div>
        </ng-scrollbar>
      </div>

      <button class="ap-legend-scroll-button"
              *ngIf="contentScrollable | async"
              (click)="moveRight()">
        <span class="k-icon k-i-arrow-chevron-right"></span>
        <span class="k-icon k-i-arrow-chevron-right"></span>
      </button>
    </div>
  `,
})
export class ApLegendScrollerComponent implements AfterContentInit {

  @ViewChild('scrollBar', {static: true}) scrollBar: NgScrollbar;
  @ViewChild('scrollableView', {static: true, read: ElementRef}) scrollableView: ElementRef;
  @ViewChild('scrollerContent', {static: true, read: ElementRef}) scrollerContent: ElementRef;
  public contentScrollable = new BehaviorSubject<boolean>(false);
  public states: { [key: string]: boolean } = {};
  public legends$: Observable<ILegend[]> = of([]);

  constructor(private mapStore: MapStore,
              private translationStore: TranslationStore,
              private legendScrollerService: ApLegendScrollerService) {
    this.mapStore.Layers.onChangeTrackLayerStyle.subscribe(e => this.selectionChange(e));
  }

  public ngAfterContentInit(): void {
    this.legendScrollerService.selectionChange.subscribe(e => this.selectionChange(e));
    this.legends$ = this.mapStore.Legends.All$
      .pipe(
        debounceTime(250), // without delay we often get 'ExpressionHasBeenChanged...' exception
        map(l => {
          l = l.Convert(_ => ({
            ..._,
            title: this.translationStore.FindTranslationForSelectedLanguage(_.title),
          }));
          l.SortBy(['title'], [ListSortOrder.ASC]);
          return l;
        }));
  }

  updateScrollbar(): void {
    if (!this.scrollerContent || !this.scrollableView) {
      return;
    }
    setTimeout(() => {
      this.contentScrollable.next(this.scrollerContent.nativeElement.scrollWidth > this.scrollableView.nativeElement.scrollWidth);
    }, 1);
  }

  moveRight(): void {
    if (!this.contentScrollable.getValue() || !this.scrollBar) {
      return;
    }
    this.scrollBar.scrollTo({
      right: 1,
    }).then();
  }

  moveLeft(): void {
    if (!this.contentScrollable.getValue() || !this.scrollBar) {
      return;
    }
    this.scrollBar.scrollTo({
      left: 1,
    }).then();
  }

  selectionChange($event: ISelectionChangeEvent): void {
    switch ($event.legend?.id) {
      case LEGEND_IDS.NUTRIENT_DISTRIBUTION:
        this.mapStore.Layers.FieldDistributionLegendNutrientChange.emit($event.selectionKeys[0]);
        break;
      case LEGEND_IDS.TRACK:
        if (this.mapStore.Layers.TrackLayer) {
          this.mapStore.Layers.TrackLayer.layer.setStyle(f => {
            const props = f.getProperties();
            if (props?.data && typeof props?.data === typeof '') {
              const d = JSON.parse(props.data);
              if (!StringFactory.IsNullOrEmpty(d?.data?.markerType)) {
                return new OlStyle({
                  image: new OlIcon({
                    anchor: [0.5, 73],
                    anchorXUnits: IconAnchorUnits.FRACTION,
                    anchorYUnits: IconAnchorUnits.PIXELS,
                    src: d.data.markerType === 'startMarker' ? '/assets/img/start_drop.png' : '/assets/img/stop_drop.png',
                  }),
                });
              }
            }
            return new OlStyle({
              stroke: new OlStyleStroke({
                color: this.getTrackColor(JSON.parse(props.data).data.data, $event, '#ffffff'),
                width: 2,
              }),
            });
          });
        }
        break;
      case LEGEND_IDS.NEED:
        this.mapStore.Layers.NeedLegendChange.emit($event);
        break;
      case LEGEND_IDS.NUTRIENT_PLANNING:
        this.mapStore.Layers.NutrientPlaningLegendChange.emit($event);
        break;
    }
  }

  private getTrackColor(data: any, e: ISelectionChangeEvent, defaultColor: string): string {
    if (!data.hasOwnProperty(this.translateDataKeys(e.selectionKeys[1]))) {
      return defaultColor;
    }
    const value = parseFloat(data[this.translateDataKeys(e.selectionKeys[1])]);
    if (isNaN(value)) {
      return defaultColor;
    }
    let breaks;
    if (typeof (e.legend.values) === 'function') {
      breaks = e.legend.values(...e.selectionKeys);
    } else {
      breaks = e.legend.values[e.selectionKeys.Join('_')];
    }
    for (const br of breaks) {
      const legendValue = parseFloat(br.title);
      if (isNaN(legendValue)) {
        continue;
      }
      if (value <= legendValue) {
        return br.color;
      }
    }
    return breaks[breaks.length - 1].color;
  }

  private translateDataKeys(key: string): string {
    switch (key) {
      case 'Speed':
        return 'speed';
      case 'Tank':
        return 'Fuel-Total-Used';
      case 'WorkState':
        return 'Work-State';
      case 'Fuel Rate':
        return 'Fuel-Rate';
      case 'Engine Speed':
        return 'Engine-Speed';
      case 'ApplicationRate':
        return 'Engine-Actual-Percent-Torque';
      default:
        return key;
    }
  }
}
