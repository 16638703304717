import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ApStringService {
  public format(template: string, ...args: (string | number)[]): string {
    try {
      if (!template) {
        return '';
      }
      const regexPattern = /\{(\d+)}/g;
      // Find all placeholders and their indices
      const placeholders: number[] = [];
      template.replace(regexPattern, (_, num) => {
        placeholders.push(parseInt(num, 10));
        return '';
      });
      // Create mapping based on sorted placeholder numbers
      const valueMap = new Map<number, string>();
      placeholders
        .sort((a, b) => a - b)
        .forEach((num, index) => {
          if (index < args.length) {
            valueMap.set(num, String(args[index]));
          }
        });
      // Replace placeholders with mapped values
      return template.replace(regexPattern, (_, num) => {
        return valueMap.get(parseInt(num, 10)) || 'undefined';
      });
    } catch (ex) {
      console.warn(ex);
      return '';
    }
  }
}
