export abstract class TaskManagementConstants {
  /*
   * Application mode definitions
   */
  public static APPL_MODE_CONST = 'Global__RateConst';
  public static APPL_MODE_VARIABEL = 'Global__RateVariable';

  /*
   * Operation mode definitions
   */
  public static OPERATION_MODE_ABS_RAPS = 'Tasks__Title_AbsN';
  public static OPERATION_MODE_NFERT = 'Global__NFertilization';
  public static OPERATION_MODE_CONST = 'Global__RateConst';
  public static OPERATION_MODE_BONITUR = 'Worktype_Category_Bonitur';
  public static OPERATION_MODE_TARGET_RATE_N = 'OpMode_TargetRateN';
  public static OPERATION_MODE_APPLICATION_MAP = 'AgriConnect__With_AppMap';
  public static OPERATION_MODE_APPLICATION_MAP_ABSOLUTE = 'Tsk_Mgmt_AppMapAbs';
  public static OPERATION_MODE_PS_ABS = 'Task_Mgmt_PS_Absolut';
  public static OPERATION_MODE_PS_TARGET = 'Task_Mgmt_PS_Target';

  /*
  * Export formats
  */
  public static EXPORT_FORMAT_PDF = 'Task_Mgmt_Format_Pdf';
  public static EXPORT_FORMAT_SHP_POLYGON = 'Task_Mgmt_Format_Shape_Polygon';
  public static EXPORT_FORMAT_SHP_MULTIPOLYGON = 'Task_Mgmt_Format_Shape_Multipolygon';
  public static EXPORT_FORMAT_ISO = 'Task_Mgmt_Format_Isoxml';
  public static EXPORT_FORMAT_PFBOX_RST = 'Task_Mgmt_Format_PfBox_rst';

  /*
   * Export targets
   */
  public static EXPORT_TARGET_DOWNLOAD = 'Task_Mgmt_Target_Download';
  // public static EXPORT_TARGET_JD = 'Task_Mgmt_Target_JD'; - not implemented, yet
  public static EXPORT_TARGET_MAIL = 'Task_Mgmt_Target_Mail';
}
