import {IStateStore}                                                                          from '../../ap-interface';
import {ApplicationSettingsLoad, ApplicationSettingsLoadFail, ApplicationSettingsLoadSuccess} from 'invoker-transport';
import {Injectable}                                                                           from '@angular/core';
import {Store}                                                                                from '../index';
import {SafeBehaviorSubject}                                                                  from 'ts-tooling';
import {
  ApSignalrService
}                                                                                             from '../../ap-core/services/ap-signalr.service';
import IApplicationSettings = Data.Agronomic.IApplicationSettings;

export interface IApplicationSettingsParameter {
  max: number;
  min: number;
  slope: number;
  uniform: number;
  slopeShown: boolean;
  deadBioMass: number | null;
  targetValue: number;
  recommendation: number | null;
}

interface IApplicationSettingsStore extends IStateStore<IApplicationSettings> {
}

@Injectable({providedIn: 'root'})
export class ApplicationSettingsStore extends Store<IApplicationSettingsStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false
    });
    backend.registerObservable(ApplicationSettingsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(ApplicationSettingsLoadFail).subscribe(() => {
      super.Mutate(s => s.data, () => []);
      super.SetLoadFailState();
    });
    this.LoadApplications();
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public get Applications$(): SafeBehaviorSubject<IApplicationSettings[]> {
    return this.Listen((s) => s.data);
  }

  public get Applications(): IApplicationSettings[] {
    return this.Applications$.getValue();
  }

  public LoadApplications(): void {
    super.SetLoadState();
    this.DispatchBackend(new ApplicationSettingsLoad([]));
  }
}
