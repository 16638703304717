import {Injectable}    from '@angular/core';
import {
  PlanningEntryComponentDataType
}                      from '../../ap-nutrients-management-entry-components/ap-nutrient-planning-configs/nutrient-planning-types';
import {
  ApDynComponentComponent
}                      from '../../../ap-dyncomponent/ap-dyncomponent.component';
import {
  BasePlanningEntryService
}                      from '../../ap-nutrients-management-entry-components/ap-nutrient-planning-configs/base-planning-entry-service';
import {ObjectFactory} from 'ts-tooling';
import {ApGuidUtil}    from '../../../ap-utils';
import {
  ApElementType
}                      from '../../../ap-interface/enums/ap-elements-type.enum';
import {UnitsStore}    from '../../../stores/common/units.store';
import {LoginStore}    from '../../../stores/login/login.store';
import {DriverStore}   from '../../../stores/docu/driver.store';
import {MachineStore}  from '../../../stores/docu/machine.store';
import {
  InstrumentStore
}                      from '../../../stores/docu/instrument.store';
import {
  FertilizerStore
}                      from '../../../stores/base-data/fertilizer.store';
import {
  CampaignYearStore
}                      from '../../../stores/login/campaignyear.store';
import {
  ApplicationModesEnum,
  ApplModesStore
}                      from '../../../stores/common/appl-modes.store';
import {
  OperationModesEnum,
  OperationModesStore
}                      from '../../../stores/common/operation-modes.store';
import {
  ApDateService
}                      from '../../../ap-core/services/ap-date-service';
import {
  ApNutrientManagementDateService
}                      from '../../../ap-utils/service/ap-nutrient-management-date.service';
import {
  NPlanningEntryComponentData
}                      from '../types/n-fertilization-planning-entry.types';
import {
  IPlanningWizardOverviewStatsItem,
  PlanningWizardOverviewModuleStats
}                      from '../../../statistics/components/charts/planning-wizard-overview-stats/ap-planning-wizard-overview-stats-types';
import {ApStatsType}   from '../../../ap-interface';
import {
  ApTranslationService
}                      from '../../../ap-utils/service/ap-translation.service';
import {
  StatisticStore
}                      from '../../../stores/statistic/statistic.store';
import {
  FertilizationManagementService
}                      from '../../../services/data/fertilization-management.service';
import {
  NFertilizationCommonEntryService
}                      from './n-fertilization-common-entry.service';
import {
  INFertilizationPlanningWizardSelectionStatsData
}                      from '../../../statistics/components/charts/n-fertilization-planning-wizard/ap-n-fertilization-planning-wizard-selection-stats-types';
import {
  StatisticService
}                      from '../../../services/data/statistic.service';
import INFertilizationSummary = Data.TaskManagement.INFertilizationSummary;
import IGuid = System.IGuid;
import INFertilizationPlanBook = Data.TaskManagement.INFertilizationPlanBook;
import IUnit = Data.Common.IUnit;
import IApplicationMode = Data.Common.IApplicationMode;
import IOperationMode = Data.Common.IOperationMode;

@Injectable()
export class NFertilizationPlanningEntryService extends BasePlanningEntryService {
  private _summaryForCreate: INFertilizationSummary | undefined;
  private _planBookCommonData: INFertilizationPlanBook | undefined;

  constructor(private unitStore: UnitsStore,
              private loginStore: LoginStore,
              private driverStore: DriverStore,
              private machineStore: MachineStore,
              private statisticStore: StatisticStore,
              private instrumentStore: InstrumentStore,
              private fertilizerStore: FertilizerStore,
              private campaignYearStore: CampaignYearStore,
              private applicationModesStore: ApplModesStore,
              private operationModesStore: OperationModesStore,
              private dateService: ApDateService,
              private statisticService: StatisticService,
              private translationService: ApTranslationService,
              private fertilizationManagementService: FertilizationManagementService,
              private nutrientManagementDateService: ApNutrientManagementDateService,
              private nFertilizationCommonEntryService: NFertilizationCommonEntryService) {
    super();
  }

  private createSummaryCalled = false;

  public initData(): void {
    super.initData();
  }

  public get summary(): INFertilizationSummary | undefined {
    return this._summaryForCreate;
  }

  public setSubscriptions(_: ApDynComponentComponent): void {
    throw new Error('Method not implemented.');
  }

  public clearData(): void {
    this._summaryForCreate = null;
    this._isSaved = false;
    this._isNewMode = false;
  }

  public createSummaryAndApplyValues(application: ApplicationModesEnum, operationMode: OperationModesEnum,
                                     nPlanBooks: Data.TaskManagement.INFertilizationPlanBook[] | undefined, formValues: {}): void {
    if (this.createSummaryCalled) {
      return;
    }
    if (application) {
      this.createNewSummary(application, operationMode, nPlanBooks);
      this.applySummaryToFormValues(formValues);
      this.createSummaryCalled = true;
    }
  }

  protected createNewSummary(application: ApplicationModesEnum, operationMode: OperationModesEnum, nPlanBooks: INFertilizationPlanBook[]): void {
    this._isNewMode = this.componentData.Type === PlanningEntryComponentDataType.Create;
    if (this.componentData.Summary) {
      const planBook = ObjectFactory.Copy(nPlanBooks.find(npb => !npb.BookedAt));
      this._summaryForCreate = this.componentData.Summary;
      this._summaryForCreate.Id = ApGuidUtil.generateNewGuid();
      this._planBookCommonData = planBook;
      this._planBookCommonData.Id = ApGuidUtil.generateNewGuid();
    } else {
      this._isNewMode = true;
      this._shouldDeselectFieldsOnClose = super.getSelectedFields.length > 0;
      const selectedYear = this.campaignYearStore.getSelectedCampaignYear();
      const farmApplDate = this.dateService.getDateNoonToFarmTime(this.nutrientManagementDateService.GetOutputDate()) ?? new Date(Math.min(new Date().valueOf(), new Date(selectedYear.DefaultEnd).valueOf()));
      const operationModeObj = this.operationModesStore.Listen(s => s.data).getValue().Find((o) => o.Key === operationMode);
      const applicationModeObj = this.applicationModesStore.Listen(s => s.data).getValue().Find((o) => o.Key === application);
      this._summaryForCreate = this._generateNSummary(ApGuidUtil.generateNewGuid(), '', this.loginStore.SelectedFarmId,
        applicationModeObj, farmApplDate, operationModeObj, this.unitStore.getUnit('kgN/ha'), null);
      this._planBookCommonData = this._generateCommonPlanBookData(ApGuidUtil.generateNewGuid(), this.loginStore.SelectedFarmId,
        0, this._summaryForCreate.Unit, applicationModeObj, null, null, null,
        farmApplDate, operationModeObj, ApElementType.N, null, null, null);
    }
  }

  // Temporary method until full refactoring of NFert component. FormValues assigning will be deprecated then
  protected applySummaryToFormValues(formValues: {}): void {
    formValues['FertilizationDate'] = new Date(this.summary.ApplicationDate);
    formValues['DeadBioMass'] = this.summary.AdditionalValue?.DeadBiomass;
    formValues['YieldExpectation'] = this.summary.AdditionalValue?.ExpectedYield;
    formValues['Potential'] = this.summary.AdditionalValue?.NMinPotential;
    formValues['Application'] = this.summary.ApplicationMode?.Key;
    formValues['OperationMode'] = this.summary.OperationMode?.Key;
    formValues['ControlFactor'] = this.summary.Slope; // : this._isRape() ? 1 : ,
    formValues['Note'] = this.summary.Title;

    // getProductFieldSet
    const product = this.fertilizerStore.getFertilizer(this._planBookCommonData.ProductId);
    if (product) {
      formValues['Group'] = product.IsOrganic ? 1 : 0;
      formValues['Product'] = this._planBookCommonData.ProductId;
    }

    // TargetRate
    formValues['ECStage'] = this._planBookCommonData.ECValue;

    if (this.summary.ApplicationMode?.Key !== 'Global__RateConst') {
      if (this.summary.OperationMode?.Key !== 'OpMode_TargetRateN') {
        // Normal mode
        formValues['Maximum'] = this.summary.UserApplicationMaximum;
        formValues['Minimum'] = this.summary.UserApplicationMinimum;
        formValues['Amount'] = this.summary.UserApplicationRate;
        formValues['Threshold'] = this.summary.Threshold;
        formValues['Constant'] = this.summary.UserApplicationConstant;
      } else {
        // TargetRateN mode
        formValues['TargetRate'] = this.summary.UserApplicationRate;
        formValues['ControlRange'] = this.summary.UserApplicationMaximum - this.summary.UserApplicationRate;
        formValues['Threshold'] = this.summary.Threshold;
      }
    } else {
      // Global__RateConst mode
      formValues['Constant'] = this.summary.UserApplicationConstant;
    }

    formValues['Machine'] = this.machineStore.getMachineById(this._planBookCommonData.ExportMachine);
    formValues['Instrument'] = this.instrumentStore.getInstrumentById(this._planBookCommonData.ExportInstrument);
    formValues['Driver'] = this.driverStore.getDriverById(this._planBookCommonData.ExportDriver);
  }

  private _generateNSummary(id: IGuid, title: string, farmId: number,
                            applMode: IApplicationMode, applDate: Date, operationMode: IOperationMode, unit: IUnit, planNumber: number | null): INFertilizationSummary {

    return {
      Id: id,
      Title: title,
      FarmId: farmId,
      OperationMode: operationMode,
      PlanNumber: planNumber,
      AdditionalValue: undefined,
      ApplicationDate: applDate,
      ApplicationMode: applMode,
      ChangedAt: undefined,
      ChangedBy: 0,
      CreatedAt: undefined,
      CreatedBy: 0,
      DeletedAt: undefined,
      DeletedBy: 0,
      ECValue: 0,
      Slope: 0,
      Threshold: 0,
      Unit: unit,
      UserApplicationConstant: 0,
      UserApplicationMaximum: 0,
      UserApplicationMinimum: 0,
      UserApplicationRate: 0,
      Version: 0
    };
  }

  private _generateCommonPlanBookData(id: IGuid, farmId: number, productId: number | null, applUnit: IUnit,
                                      applMode: IApplicationMode, applConst: number | null, applMin: number | null, applMax: number | null,
                                      applDate: Date, operationMode: IOperationMode, element: ApElementType, driverId: IGuid | null, machineId: IGuid | null,
                                      instrumentId: IGuid | null): INFertilizationPlanBook {
    return {
      Id: id,
      FarmId: farmId,
      ProductId: productId,
      Unit: applUnit,
      ApplicationMode: applMode,
      ApplicationConst: applConst,
      ApplicationMin: applMin,
      ApplicationMax: applMax,
      ApplicationDate: applDate,
      OperationMode: operationMode,
      RasterStatistics: [],
      Element: element,
      ExportDriver: driverId,
      ExportMachine: machineId,
      ExportInstrument: instrumentId,
      // Empty properties
      ApplicationRate: 0,
      Area: 0,
      BookedAt: undefined,
      BookedBy: 0,
      ChangedAt: undefined,
      ChangedBy: 0,
      CreatedAt: undefined,
      CreatedBy: 0,
      DeletedAt: undefined,
      DeletedBy: 0,
      ECValue: 0,
      ExportFormat: 0,
      ExportTarget: '',
      ExportedAt: undefined,
      ExportedBy: 0,
      Factor: 0,
      FieldGeomId: undefined,
      FieldId: undefined,
      Geom: undefined,
      MainCropId: 0,
      Note: '',
      PlannedArea: 0,
      RasterId: undefined,
      SecondCropId: 0,
      Slope: 0,
      Status: undefined,
      Summary: undefined,
      TEnd: undefined,
      TStart: undefined,
      Threshold: 0,
      ValidFrom: undefined,
      ValidTo: undefined,
      Version: 0,
      JobInput: ''
    };
  }

  public get componentData(): NPlanningEntryComponentData {
    return this._componentData as NPlanningEntryComponentData;
  }

  public generateOverviewStatistic(operationMode: OperationModesEnum, selectedItems: any[], totalItems: any[]): void {
    const emptyTitle = `${this.translationService.translate(operationMode)}
      ${this.translationService.translate('Nutrients__StatisticsTitle')}`;
    const items = !!selectedItems && selectedItems.length > 0 ? selectedItems : totalItems;
    if (!items || items.length <= 0 || items.some(x => x.ColumnLoading)) {
      this.statisticStore.setStatisticData<IPlanningWizardOverviewStatsItem>(
        [], ApStatsType.PlanningWizardOverviewStats, '', emptyTitle
      );
      return;
    }
    const nStatItems: IPlanningWizardOverviewStatsItem[] = items.map(item => ({
      Module: PlanningWizardOverviewModuleStats.NFertilization,
      OperationModeKey: item.OperationMode,
      NContent: this.fertilizationManagementService.getFertilizerContentN(item?.ProductId),
      Area: item?.Area,
      Min: this.nFertilizationCommonEntryService.isApplicationMapOrAbsolute(item?.OperationMode) ? item?.Minimum : item?.Constant,
      Max: this.nFertilizationCommonEntryService.isApplicationMapOrAbsolute(item?.OperationMode) ? item?.Maximum : item?.Constant,
      Average: this.nFertilizationCommonEntryService.isApplicationMapOrAbsolute(item?.OperationMode) ? item?.Amount : item?.Constant,
      ProductId: item?.ProductId
    }));
    const title = this._getTitleForStatistic(items, totalItems);
    this.statisticStore.setStatisticData<IPlanningWizardOverviewStatsItem>(
      nStatItems, ApStatsType.PlanningWizardOverviewStats, title, emptyTitle
    );
  }

  public generateFieldStatistic(items: any[]): void {
    const emptyTitle = this.translationService.translate('Global__latest_Sensor_reading');
    if (!items || items.length <= 0) {
      this.statisticStore.setStatisticData<INFertilizationPlanningWizardSelectionStatsData>(
        [], ApStatsType.NFertilizationPlanningWizardSelectionStats, '', emptyTitle
      );
      return;
    }
    const nStatItems: INFertilizationPlanningWizardSelectionStatsData[] = items.map((item: any) => ({
      FieldName: item?.Name,
      Area: item?.Area,
      FieldNumber: item?.Nr,
      FieldSubNumber: item?.TNr,
      SensorAvgN: item?.SensorAvg,
      SensorDate: item?.SensorDate,
      SensorMinN: item?.SensorMin,
      SensorMaxN: item?.SensorMax
    }));
    const title = this._getTitleForStatistic(items, items);
    this.statisticStore.setStatisticData<INFertilizationPlanningWizardSelectionStatsData>(
      nStatItems, ApStatsType.NFertilizationPlanningWizardSelectionStats, title, emptyTitle
    );
  }

  private _getTitleForStatistic(selectedItems: any[], allItems: any[]): string {
    const dataForTitles = allItems.map(x => ({
      AreaHa: x.Area,
      Selected: selectedItems.some(z => z.Id === x.Id)
    }));
    return this.statisticService.generateFieldsAreaTitle(dataForTitles);
  }
}
