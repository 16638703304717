<div class="ap-toolbar-control" data-cy="app_page_logout_control">
  <kendo-dropdownbutton
    [class.userprofile-button]="true"
    [class.dark-bg]="true"
    [popupSettings]="popupSettings"
    [data]="userProfile"
    [icon]="'user'"
    [id]="'user-profile-button'"
    [textField]="'text'"
    [accessKey]="'data_cy'"
    data-cy="app_page_logout_12"
    [aptooltip]="('Global_User_Profile' | translate) + ' ' + user?.EMail"
    [perpetual]="true"
    (itemClick)="onItemClicked($event)">
    <ng-template kendoDropDownButtonItemTemplate let-dataItem>
      <span class="ap-userprofile-item">{{dataItem.text}}</span>
    </ng-template>
  </kendo-dropdownbutton>
</div>
