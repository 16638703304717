import {Injectable}                          from '@angular/core';
import {CookieService}                       from 'ngx-cookie-service';
import {ApSignalrService}                                      from './ap-signalr.service';
import {Config, LoginActions, NetTypes, UserLogin, UserLogout} from 'invoker-transport';

/**
 * Service for cookies
 */
@Injectable({providedIn: 'root'})
export class ApCookieService {
  public afterSetCookie: () => void = null;
  public onSetCookie: (arg: { data: string, durationHours: number }) => any;
  public onRemoveCookie: () => any;
  public onGetCookie: () => string;

  /**
   * connect to the stores
   */
  constructor(private cookieService: CookieService,
              private backend: ApSignalrService) {
    this.backend.cookieGetter = () => this.getCookie();
    this.backend.getListenerByActionType(LoginActions.SET_COOKIE)
      .subscribe((d) => {
        if (!d.Data) {
          return;
        }
        this.setCookie(d.Data, this.CalcAgriportSessionDurationHours());
        setTimeout(() => this.login(), 1);
      });
    this.backend.getListenerByActionType(LoginActions.UPDATE_COOKIE)
      .subscribe((d) => {
        if (!d.Data) {
          return;
        }
        this.setCookie(d.Data, this.CalcAgriportSessionDurationHours());
      });
    this.backend.getListenerByActionType(LoginActions.USER_LOGIN_SUCCESS)
      .subscribe(() => {
        ApSignalrService.isLoggedIn = true;
      });
    this.backend.getListenerByActionType(LoginActions.USER_LOGOUT_FROM_SYSTEM)
      .subscribe(() => {
        ApSignalrService.isLoggedIn = false;
      });
  }

  /**
   * Calculates the session/cookie expire date
   * The cookie expires after 365 approximately around midnight, but is extended
   * whenever user uses agriport in the meantime
   */
  public CalcAgriportSessionDurationHours(): number {
    // Let the session and cookie expire around midnight.
    // Anyway, we ignore the minutes here. The cookie should just expire around midnight
    // to minimize the chance of loosing sessions when the user works with Agriport
    const currentTime = new Date();
    const hoursUntilMidnight = currentTime.getHours() + 1;
    return  365 * 24 + hoursUntilMidnight;
  }

  public login(): void {
    const hash = this.getCookie();
    if (hash) {
      this.backend.send(new UserLogin([
        {Name: 'hash', Type: NetTypes.STRING, Value: hash}
      ]));
    }
  }

  public logout(): void {
    const hash = this.getCookie();
    if (hash) {
      this.backend.send(new UserLogout([
        {Name: 'hash', Type: NetTypes.STRING, Value: hash},
      ]));
      ApSignalrService.isLoggedIn = false;
      this.deleteCookie();
    }
  }

  /**
   * set the cookie
   */
  setCookie(data: string, durationHours: number): void {
    if (!this.onSetCookie || typeof this.onSetCookie !== 'function') {
      const needsSecureCookie = Config.BackendAddress.Contains('https:');
      const expireDate = new Date();
      expireDate.setHours(expireDate.getHours() + durationHours);
      this.cookieService.set('Agriport', data, expireDate, '/', null, needsSecureCookie, 'Strict');
    } else {
      this.onSetCookie({
        data, durationHours,
      });
    }
  }

  /**
   * delete the cookie
   */
  deleteCookie(): void {
    if (!this.onRemoveCookie || typeof this.onRemoveCookie !== 'function') {
      this.cookieService.delete('Agriport', '/');
    } else {
      this.onRemoveCookie();
    }
  }

  /**
   * get the cookie
   */
  getCookie(): string {
    if (!this.onGetCookie || typeof this.onGetCookie !== 'function') {
      return this.cookieService.get('Agriport');
    } else {
      return this.onGetCookie();
    }
  }
}
