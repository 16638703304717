import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  NgZone,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild
}                                                                        from '@angular/core';
import {ApMenuUrls, ApVisibleStates, MapViewMode, PageMode, SidebarMode} from '../../ap-interface';
import {BehaviorSubject, combineLatest, Observable}                      from 'rxjs';
import {LOGO_SCALE}                                                      from '../../ap-animation/logo-scale.animation';
import {ANIMATE_CHILD, SIDEBAR_TRANSLATE}                                from '../../ap-animation/sidbar.animation';
import {
  CHANGE_WIDTH_MAIN_VIEW,
  CHANGE_WIDTH_MAP_VIEW
}                                                                        from '../../ap-animation/change-width.animation';
import {
  ApWindowHelperService,
  NetworkStatus
}                                                                        from '../../ap-core/services/ap-window-helper.service';
import {ApMapInstance}                                                   from '../../ap-map';
import {
  TRANSLATE_COMPONENT
}                                                                        from '../../ap-animation/translate-component.animation';
import {GetRoundNumericPipe}                                             from '../../ap-utils';
import {ApMetricService}                                                 from '../../modules/metrics/ap-metric.service';
import {CropTypeStore}                                                   from '../../stores/base-data/crop.types.store';
import {DomSanitizer}                                                    from '@angular/platform-browser';
import {
  UserSettingsStore
}                                                                        from '../../stores/settings/usersettings.store';
import {SettingsStore}                                                   from '../../stores/base-data/settings.store';
import {FieldStore}                                                      from '../../stores/farm/field.store';
import {PageStore}                                                       from '../../stores/layout/page.store';
import {MenuStore}                                                       from '../../stores/layout/menu.store';
import {FormStore}                                                       from '../../stores/layout/form.store';
import {MapViewStore}                                                    from '../../stores/layout/mapview.store';
import {ActionStore}                                                     from '../../stores/docu/action.store';
import {LanguageStore}                                                   from '../../stores/translation/language.store';
import {
  TranslationStore
}                                                                        from '../../stores/translation/translation.store';
import {RouterStore}                                                     from '../../stores/router/router.store';
import {MapStore}                                                        from '../../stores/map/map.store';
import {PopupService}                                                    from '@progress/kendo-angular-popup';
import {TooltipDirective}                                                from '@progress/kendo-angular-tooltip';
import {ApTooltipService}                                                from '../../ap-tooltip/ap-tooltip.service';
import {LoginStore}                                                      from '../../stores/login/login.store';
import {
  AgriportConstantsStore
}                                                                        from '../../stores/common/agriport-constants.store';
import {
  CropGroupStore
}                                                                        from '../../stores/base-data/crop.groups.store';
import {APP_CONFIGURATION}                                               from '../../ap-core/config';
import {
  ClientOutdated, SessionLost,
  SessionOutdated, SessionPing
}                                                                        from '../../../../projects/invoker-transport/src/lib/actions/administration/system.action';
import {NotifyStore}                                                     from '../../stores/dialog/notify.store';
import {debounceTime, delay, map}                                        from 'rxjs/operators';
import {
  ApRoleTypeService
}                                                                        from '../../services/common/ap-role-type.service';
import {
  ApSignalrService
}                                                                        from '../../ap-core/services/ap-signalr.service';
import ILanguage = Data.Language.ILanguage;
import ApValidationLevel = Data.Api.Validation.ApValidationLevel;
import IApValidationResult = Data.Api.Validation.IApValidationResult;
import {Clipboard}                                                       from '@angular/cdk/clipboard';

const SIDEBAR_MAX_WINDOW_WIDTH = 992;

/**
 * Main Component for the Application Layout
 */
@Component({
  selector: 'ap-root',
  templateUrl: 'ap-layout.component.html',
  animations: [
    LOGO_SCALE,
    SIDEBAR_TRANSLATE,
    ANIMATE_CHILD,
    CHANGE_WIDTH_MAP_VIEW,
    CHANGE_WIDTH_MAIN_VIEW,
    TRANSLATE_COMPONENT
  ]
})
export class ApLayoutComponent implements OnInit, AfterViewInit {
  private static OutdatedClientMessageErrorKey = 'Global__ClientOutdated';
  private static OutdatedSessionMessageErrorKey = 'Global__SessionOutdated';
  private static LostSessionMessageErrorKey = 'Global__SessionLost';

  /**
   * bind the PageModes to use it in the View
   */
  public pageModes = PageMode;
  public mapViewModes = MapViewMode;
  public sidebarMode$: Observable<SidebarMode>;
  public mapViewMode$: Observable<MapViewMode>;
  public pageMode$: Observable<PageMode>;
  public menuAnimationState$: Observable<string>;
  public language$: Observable<ILanguage>;
  public languages$: Observable<Array<ILanguage>>;
  public formState$: Observable<ApVisibleStates>;
  public tabViewVisible = true;
  public networkStatusChanged: BehaviorSubject<NetworkStatus> = new BehaviorSubject(NetworkStatus.Online);
  public networkIsOffline = false;

  public disableLegalInfoButton = false;
  public disableLangaugeDropDown = false;
  public windowInnerHeight: number;
  public windowOuterHeight: number;
  public windowInnerWidth: number;
  public windowOuterWidth: number;
  public visiblePanel = true;
  public innerLoadingVisible$ = this.loginStore.Listen(s => s.dataLoading);
  public longOperationOverlayVisible$ = combineLatest([
    this.loginStore.Listen(s => s.longOperationInProgress),
    this.loginStore.Listen(s => s.dataLoading)]).pipe(map(([lop, data]) => lop && !data));
  public clientErrorOverlayVisible$ = this.loginStore.Listen(s => s.clientErrorOccurred)
    .pipe(map((clientError) => clientError && !ApRoleTypeService.hasDeveloperRole(this.loginStore.User)));
  public isDebugMode = false;

  /**
   * The tooltip's template
   */
  @ViewChild('toolTipTemplate') public toolTipTemplateRef: TemplateRef<any>;
  /**
   * host binding for 'kendotooltip' attribute/directive. Otherwise,
   * it is not possible to set the kendotooltip directive at root level.
   * Tooltip needs to be set at root-level to have tooltips on kendo popups(dropdown, ...)
   * working.
   */
  @HostBinding('attr.kendotooltip') kendoTooltip: TooltipDirective;

  private static OutdatedMessage(errorKey: string): IApValidationResult {
    return {
      ErrorKey: errorKey,
      Level: ApValidationLevel.Warning,
      UserId: 0,
      UserName: '',
      Parameters: [],
      Counter: 1,
      DisplayTimeout: 0,
    };
  }

  /**
   * Listen to special key shortcut to enable and disable Debug mode for Agriport
   * (available only for Agricon admin users)
   */
  @HostListener('document:keydown.control.alt.space', ['$event']) onKeydownControlAltSpaceHandler(_: KeyboardEvent): void {
    if (ApRoleTypeService.hasAgriconRole(this.loginStore.User)) {
      this.loginStore.switchDebugMode();
    }
  }

  constructor(private languageStore: LanguageStore,
              private translationStore: TranslationStore,
              private mapViewStore: MapViewStore,
              private menuStore: MenuStore,
              private pageStore: PageStore,
              private windowHelper: ApWindowHelperService,
              private routerStore: RouterStore,
              private formStore: FormStore,
              private actionStore: ActionStore,
              private fieldStore: FieldStore,
              private mapSore: MapStore,
              private croptypeStore: CropTypeStore,
              private settingsStore: SettingsStore,
              private roundNumericPipe: GetRoundNumericPipe,
              private metric: ApMetricService,
              private userSettingStore: UserSettingsStore,
              private dom: DomSanitizer,
              private apTooltipService: ApTooltipService,
              private tooltipWrapper: ElementRef,
              private ngZone: NgZone,
              private renderer: Renderer2,
              private popupService: PopupService,
              private loginStore: LoginStore,
              private agriportConstants: AgriportConstantsStore,
              private cropGroupsStore: CropGroupStore,
              private backend: ApSignalrService,
              private notifyStore: NotifyStore,
              private clipboardService: Clipboard
  ) {
    this.backend.versionGetter = () => APP_CONFIGURATION.Revision;
    this.backend.registerObservable(ClientOutdated).subscribe(() =>
      this.handleSystemReloadMessage(ApLayoutComponent.OutdatedClientMessageErrorKey));
    this.backend.registerObservable(SessionOutdated).subscribe(() =>
      this.handleSystemReloadMessage(ApLayoutComponent.OutdatedSessionMessageErrorKey));
    this.backend.registerObservable(SessionLost).subscribe(() =>
      this.handleSystemReloadMessage(ApLayoutComponent.LostSessionMessageErrorKey));
    // When backend is restarted (e.g. due to a release), all AgriportSessions get lost.
    // The client does not get notified about this.
    // Only when the user triggers a backend message (e.g. farm change or save operations)
    // the client would receive an error from the backend that the AgriportSession was lost.
    // To avoid this, we introduced a regular ping to our backend.
    // In case the AgriportSession got lost the client will receive a message (and reload)
    // latest after this interval of currently 5 minutes.
    setInterval(() => this.backend.send(new SessionPing()), 5 * 60 * 1000);

    this.kendoTooltip = this.apTooltipService.CreateKendoDirective(this.tooltipWrapper, this.ngZone, this.renderer, this.popupService);
    this.formState$ = this.formStore.Listen(s => s.update);
    this.listenRouterUrlChanges();
    ApMapInstance.mapStore = this.mapSore;
    ApMapInstance.fieldStore = this.fieldStore;
    ApMapInstance.actionStore = this.actionStore;
    ApMapInstance.routerStore = this.routerStore;
    ApMapInstance.croptypeStore = this.croptypeStore;
    ApMapInstance.cropGroupsStore = this.cropGroupsStore;
    ApMapInstance.domSD = this.dom;
    ApMapInstance.userSettingsStore = this.userSettingStore;
    ApMapInstance.settingsStore = this.settingsStore;
    ApMapInstance.translationService = this.translationStore;
    ApMapInstance.roundNumericPipe = this.roundNumericPipe;
    ApMapInstance.clipboardService = this.clipboardService;
    this.loginStore.Listen(s => s.isDebugModeEnabled).subscribe(isDebugModeEnabled => {
      if (ApRoleTypeService.hasDeveloperRole(this.loginStore.User)) {
        this.isDebugMode = isDebugModeEnabled;
      }
    });
    this.loginStore.Listen(s => s.login).subscribe(_ => {
      ApMapInstance.hasAgriconRole = ApRoleTypeService.hasAgriconRole(this.loginStore.User);
      ApMapInstance.hasDeveloperRole = ApRoleTypeService.hasDeveloperRole(this.loginStore.User);
    });
    this.networkStatusChanged.pipe(debounceTime(1000)).subscribe((networkStatus) => {
      this.networkIsOffline = networkStatus === NetworkStatus.Offline;
    });
  }

  /**
   * Handles system messages which should trigger a client reload.
   * @param messageKey
   * @private
   */
  private handleSystemReloadMessage(messageKey: string): void {
    if (this.notifyStore.getNotify().Any(n => n.ErrorKey === messageKey)) {
      return;
    }
    this.restartClient(messageKey);
  }

  /**
   * when Component is loaded
   */
  ngOnInit(): void {
    this.agriportConstants.LoadAgriportConstants();
    this.bindStoreValues();
    this.localize();
    this.hookWindowResizeEvent();
    this.prepareView();
    this.bindNetworkStatus();
  }

  /**
   * After view is initialized.
   * Initialize tooltip as well. (once and never again)
   * There is only one kendo tooltip instance for the whole application
   */
  ngAfterViewInit(): void {
    this.apTooltipService.Init(this.toolTipTemplateRef);
  }

  /**
   *  Backend might need to know when user left page.
   *  SignalR 'disconnected' event might be delayed.
   */
  @HostListener('window:beforeunload')
  beforeunloadHandler(): void {
    this.loginStore.pageLeave();
  }

  public onFinishChangeWidth($event: any): void {
    switch ($event.toState) {
      case SidebarMode.HALF:
      case SidebarMode.CLOSE:
      case SidebarMode.MOBILE:
        this.visiblePanel = false;
        break;
    }
  }

  public onStartChangeWidth($event: any): void {
    if ($event.fromState === SidebarMode.HALF && $event.toState === SidebarMode.OPEN) {
      this.visiblePanel = true;
    }
  }

  onMapViewChangeStart(): void {
    const mapViewMode = this.mapViewStore.Listen(s => s.mode).getValue();
    if (mapViewMode !== MapViewMode.HIDE) {
      this.tabViewVisible = true;
    }
  }

  onMapViewChangeFinish(): void {
    const mapViewMode = this.mapViewStore.Listen(s => s.mode).getValue();
    if (mapViewMode === MapViewMode.HIDE) {
      this.tabViewVisible = false;
      return;
    }
    this.mapViewStore.refreshMap();
  }

  private bindStoreValues(): void {
    this.sidebarMode$ = this.menuStore.Listen(s => s.mode);
    this.mapViewMode$ = this.mapViewStore.Listen(s => s.mode).pipe(delay(1));
    this.pageMode$ = this.pageStore.Listen(s => s.mode);
    this.languages$ = this.languageStore.Listen(s => s.data);
    this.language$ = this.languageStore.Listen(s => s.selectedLanguage);
    this.menuAnimationState$ = this.menuStore.Listen(s => s.menuAnimationState);
  }

  private localize(): void {
    this.languageStore.Load();
    this.languageStore.languageLoadComplete.subscribe(() => {
      this.translationStore.LoadTranslations(this.languageStore?.SelectedLanguage?.Key);
    });
  }

  /**
   * set default Values for the Layout
   */
  private prepareView(): void {
    if (this.windowInnerWidth >= SIDEBAR_MAX_WINDOW_WIDTH) {
      this.menuStore.openSidebar();
    } else {
      this.menuStore.halfSidebar();
    }
  }

  /**
   * hook on the Window resize Event
   */
  private hookWindowResizeEvent(): void {
    this.windowHelper.registerOnResize(this.onResizeWindow.bind(this));
    this.onResizeWindow();
  }

  /**
   * when Window Size is changed we have to refresh the Size Values and
   * change the View when the Window is too small
   */
  private onResizeWindow(): void {
    this.windowInnerHeight = window.innerHeight;
    this.windowOuterHeight = window.outerHeight;
    this.windowInnerWidth = window.innerWidth;
    this.windowOuterWidth = window.outerWidth;

    if (this.windowInnerWidth < SIDEBAR_MAX_WINDOW_WIDTH && this.menuStore.getViewMode() !== SidebarMode.CLOSE) {
      this.menuStore.halfSidebar();
    } else if (this.menuStore.getSidebarExpand()) {
      this.menuStore.openSidebar();
    } else {
      this.menuStore.halfSidebar();
    }
    ApMapInstance.updateView();
  }

  public onChange(selectedLanguage: ILanguage): void {
    this.languageStore.SelectLanguage(selectedLanguage);
    this.translationStore.LoadTranslations(selectedLanguage.Key);
  }

  public onLegalNoticeClick(): void {
    const fullLegalInfoPath = `${this.windowHelper.getLocationOrigin()}/${ApMenuUrls.INFO_LEGAL}`;
    this.windowHelper.openSite(fullLegalInfoPath, '_blank');
  }

  public onContactClick(): void {
    this.windowHelper.openSite('https://www.agricon.de/ansprechpartner');
  }

  public onTeamViewerClick(): void {
    this.windowHelper.openSite(APP_CONFIGURATION.TeamViewerUrl);
  }

  public generateButtonForInnerError(): { text: string, action: () => void } {
    return {
      text: 'Back_To_Start',
      action: () => window.location.replace(ApMenuUrls.DASHBOARD)
    };
  }

  private listenRouterUrlChanges(): void {
    this.routerStore.Listen(s => s).subscribe(d => {
      if (!d) {
        return;
      }
      if (d.url.includes(ApMenuUrls.INFO_LEGAL)) {
        this.disableLegalInfoButton = true;
        this.disableLangaugeDropDown = true;
      } else {
        this.disableLegalInfoButton = false;
        this.disableLangaugeDropDown = false;
      }
      this.formStore.closeForm();
      // send the module change to the backend
      this.metric.moduleChange(d.url);
    });
  }

  private restartClient(errorKey: string): void {
    if (this.notifyStore.getNotify().Any(n => n.ErrorKey === errorKey)) {
      window.location.reload();
    }
    this.notifyStore.addMessage(ApLayoutComponent.OutdatedMessage(errorKey));
    setTimeout(() => {
      this.restartClient(errorKey);
    }, 3 * 1000);
  }

  private bindNetworkStatus(): void {
    this.windowHelper.registerNetworkStatus((networkStatus) => {
      this.networkStatusChanged.next(networkStatus);
    });
  }
}
