import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, ViewChild} from '@angular/core';
import {ApParameterValueComponent}                                           from './ap-parameter-value.component';
import ParameterRefObjectType = Data.Parameter.Enums.ParameterRefObjectType;
import IGuid = System.IGuid;
import {combineLatest, Subscription} from 'rxjs';

export enum ParameterTabs {
  Parameter = 0,
  Parameter_History = 1,
}

@Component({
  selector: 'ap-parameter',
  templateUrl: './ap-parameter.component.html'
})
export class ApParameterComponent implements AfterViewInit, OnDestroy {
  @Input() refId: IGuid;
  @Input() refObject: ParameterRefObjectType;
  @ViewChild(ApParameterValueComponent, {static: false})
  public parameterValue: ApParameterValueComponent;

  public selectedTab: ParameterTabs = ParameterTabs.Parameter;
  public ParameterRefObjectType: ParameterRefObjectType = ParameterRefObjectType.Field;

  private _onSave: EventEmitter<void> = new EventEmitter();
  private _afterViewInit: EventEmitter<void> = new EventEmitter();

  private _subscriptions: Subscription[] = [];

  constructor() {
    this._subscriptions.push(combineLatest([
      this._afterViewInit,
      this._onSave,
    ]).subscribe(() => this.parameterValue.Save()));
  }

  ngAfterViewInit(): void {
    this._afterViewInit.emit();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  selectTab(e): void {
    setTimeout(() => this.setTab(e), 1);
  }

  private setTab(e): void {
    switch (this.selectedTab) {
      case ParameterTabs.Parameter:
        break;
      case ParameterTabs.Parameter_History:
        break;
    }
    this.selectedTab = e.index;
  }

  public Save(): void {
    this._onSave.emit();
  }
}
