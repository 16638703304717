import {EventEmitter, Injectable}        from '@angular/core';
import {BehaviorSubject, Observable}     from 'rxjs';
import {ApNutrientGroup}                 from '../../../ap-interface/enums/ap-nutrient-groups.enum';
import {FertilizerStore}                 from '../../../stores/base-data/fertilizer.store';
import {UnitsStore}                      from '../../../stores/common/units.store';
import {ApDateService}                   from '../../../ap-core/services/ap-date-service';
import {ApOperationMode}                 from '../../../ap-interface/enums/ap-operation-mode.enum';
import {ApApplicationMode}               from '../../../ap-interface/enums/ap-application-mode.enums';
import {ApGuidUtil, GetRoundNumericPipe} from '../../../ap-utils';
import {ApNutrientStatisticService}      from '../../../nutrient-management/service/ap-nutrient-statistic.service';
import {SettingsStore}                   from '../../../stores/base-data/settings.store';
import {ApTranslationService}            from '../../../ap-utils/service/ap-translation.service';
import {ApElementType}                   from '../../../ap-interface/enums/ap-elements-type.enum';
import {LoginStore}                      from '../../../stores/login/login.store';
import {ApNutrientManagementDateService} from '../../../ap-utils/service/ap-nutrient-management-date.service';
import {
  INutrientPlaningSummaryData,
  ModesAndStatusForPlan,
  PlanningEntryComponentData,
  PlanningEntryComponentDataType,
  RemainDemandType
}                                        from './nutrient-planning-types';
import {CampaignYearStore}               from '../../../stores/login/campaignyear.store';
import {CampaignYearService}             from '../../../services/data/campaign-year.service';
import {ApNutrientWizardStep}            from '../../../ap-interface/enums/ap-nutrient-wizard-setup.enums';
import {MapStore}                        from '../../../stores/map/map.store';
import {ApplModesStore}                  from '../../../stores/common/appl-modes.store';
import {ElementsStore}                   from '../../../stores/common/elements.store';
import {OperationModesStore}             from '../../../stores/common/operation-modes.store';
import {FieldStore}                      from '../../../stores/farm/field.store';
import {StatisticStore}                  from '../../../stores/statistic/statistic.store';
import {NutrientStore}                   from '../../../stores/nutrients/nutrient.store';
import {ObjectFactory}                   from 'ts-tooling';
import {MachineStore}                    from '../../../stores/docu/machine.store';
import {InstrumentStore}                 from '../../../stores/docu/instrument.store';
import {DriverStore}                     from '../../../stores/docu/driver.store';
import {ApNutrientService}               from '../../../ap-utils/service/ap-nutrient.service';
import {BaseFertilizationCommonService}  from '../../../nutrient-management/service/base-fertilization-common.service';
import {BasePlanningEntryService}        from './base-planning-entry-service';
import {ApDynComponentComponent}         from '../../../ap-dyncomponent/ap-dyncomponent.component';
import {RbStore}                         from '../../../stores/nutrients/rb.store';
import IUnit = Data.Common.IUnit;
import IGuid = System.IGuid;
import ISettings = Data.BaseData.ISettings;
import IRbStatistic = Data.Nutrients.IRbStatistic;
import IBasicFertilisationSummaries = Data.TaskManagement.IBasicFertilisationSummaries;
import IBasicFertilisationPlanBooks = Data.TaskManagement.IBasicFertilisationPlanBooks;
import IField = Data.FieldManagement.IField;

@Injectable()
export class NutrientPlanningEntryService extends BasePlanningEntryService {
  public readonly closeForm = new EventEmitter<void>();
  public readonly activeWizardStep = new BehaviorSubject<ApNutrientWizardStep>(ApNutrientWizardStep.Selection);

  private _notifyAboutSelectedFieldsChanged = new BehaviorSubject<IField[]>([]);
  private _notifyAboutSelectedFieldsChanged$ = this._notifyAboutSelectedFieldsChanged.asObservable();
  private _summaryForCreate: INutrientPlaningSummaryData | undefined;
  private _summaryForEdit: IBasicFertilisationSummaries | undefined;
  private _allPlanBooksFromSummaryToEdit: boolean | undefined;
  private _settings: ISettings;
  private _globalUnitPercent: IUnit; // 3
  private _globalUnitKgPerHa: IUnit; // 1
  private _globalUnitM3PerHa: IUnit; // 4
  private _globalUnitTPerHa: IUnit; // 5

  private get componentData(): PlanningEntryComponentData {
    return this._componentData as PlanningEntryComponentData;
  }

  constructor(private rbStore: RbStore,
              private mapStore: MapStore,
              private unitStore: UnitsStore,
              private fieldStore: FieldStore,
              private loginStore: LoginStore,
              private driverStore: DriverStore,
              private machineStore: MachineStore,
              private elementStore: ElementsStore,
              private settingsStore: SettingsStore,
              private statisticStore: StatisticStore,
              private instrumentStore: InstrumentStore,
              private fertilizerStore: FertilizerStore,
              private campaignYearStore: CampaignYearStore,
              private nutrientPlanningStore: NutrientStore,
              private applicationModesStore: ApplModesStore,
              private operationModesStore: OperationModesStore,
              private dateService: ApDateService,
              private nutrientService: ApNutrientService,
              private campaignYearService: CampaignYearService,
              private translationService: ApTranslationService,
              private nutrientStatisticService: ApNutrientStatisticService,
              private baseFertilizationCommonService: BaseFertilizationCommonService,
              private nutrientManagementDateService: ApNutrientManagementDateService,
              private roundNumericPipe: GetRoundNumericPipe) {
    super();
  }

  public get isNewMode(): boolean | undefined {
    return this._isNewMode;
  }

  public get summary(): INutrientPlaningSummaryData | undefined {
    return this._summaryForCreate;
  }

  public get globalUnitPercent(): IUnit {
    return this._globalUnitPercent;
  }

  public get globalUnitKgPerHa(): IUnit {
    return this._globalUnitKgPerHa;
  }

  public get detectSelectedFieldsChanges(): Observable<IField[]> {
    return this._notifyAboutSelectedFieldsChanged$;
  }

  public initData(): void {
    super.initData();
    this._settings = this.settingsStore.FirstSetting;
    this._globalUnitKgPerHa = this.unitStore.getUnitByKey('Global__Unit_kg_ha');
    this._globalUnitPercent = this.unitStore.getUnitByKey('Global__Unit_Percent');
    this._globalUnitM3PerHa = this.unitStore.getUnitByKey('Global__UnitM3PerHa');
    this._globalUnitTPerHa = this.unitStore.getUnitByKey('Global__Unit_t_ha');
    this._createNewSummary();
    this.rememberSelectedFields(this.fieldStore.getSelectedFields(), true);
  }

  public setSubscriptions(dynComponent: ApDynComponentComponent): void {
    super.setSubscriptions(dynComponent);
  }

  public clearData(): void {
    this._revertDeletedData();
    this._summaryForCreate = undefined;
    this._isSaved = false;
    this._isNewMode = false;
    this.nutrientPlanningStore.clearNutrientPlanningStatistic();
    this.statisticStore.removeStatisticData();
    if (this._shouldDeselectFieldsOnClose) {
      this.fieldStore.changeSelectedField([]);
    }
    this.rememberSelectedFields([], false);
  }

  public rememberSelectedFields(fields: IField[], notifyAboutChanges: boolean): void {
    this._selectedFields = [...fields];
    this._shouldDeselectFieldsOnClose = this.getSelectedFields.length > 0;
    if (notifyAboutChanges) {
      this._notifyAboutSelectedFieldsChanged.next(this._selectedFields);
    }
  }

  public savePlannings(): void {
    const saveObject = this._convertingSummarySaveObject();
    this.baseFertilizationCommonService.saveSummary(saveObject);
    this._isSaved = true;
    if (!this._isNewMode) {
      this.baseFertilizationCommonService.setSelectedSummaryKey(saveObject.Id);
    }
  }

  public clearMapLayerAndLegend(): void {
    this.mapStore.Layers.NutrientPlanningLayer.clear();
    this.mapStore.Legends.removeLegend(this.mapStore.Legends.NutrientPlanningLegend);
  }

  public getRemainDemands(statistics: IRbStatistic): RemainDemandType[] {
    return [
      {
        ElementType: ApElementType.P,
        Amount: this.nutrientStatisticService.getAverageRb(4, statistics)
      },
      {
        ElementType: ApElementType.K,
        Amount: this.nutrientStatisticService.getAverageRb(5, statistics)
      },
      {
        ElementType: ApElementType.Mg,
        Amount: this.nutrientStatisticService.getAverageRb(6, statistics)
      },
      {
        ElementType: ApElementType.CaO,
        Amount: this.nutrientStatisticService.getAverageRb(7, statistics)
      }
    ];
  }

  public getElementLabelTranslation(): string {
    const element: string = this._settings.IsOxidFarm
      ? this.translationService.translate('Global__Oxyd_Label')
      : this.translationService.translate('Global__Element_Label');
    return `${this.translationService.translate('Global__Average_Symbol_Nutrient_kg_ha')} [${element}]`;
  }

  public isKgPerHOrM3PerHOrTPerHUnit(unitId: number | null): boolean {
    return unitId && (this._globalUnitKgPerHa.Id === unitId
      || this._globalUnitM3PerHa.Id === unitId
      || this._globalUnitTPerHa.Id === unitId);
  }

  public getM3HaOrTHaOrKgHaUnitIdForOrganic(nutrientGroup: ApNutrientGroup, productId: number): number {
    if (productId && productId > 0 && nutrientGroup === ApNutrientGroup.Organic) {
      const fertilizer = this.fertilizerStore.getFertilizer(productId);
      if (fertilizer) {
        return fertilizer.IsLiquid ? this._globalUnitM3PerHa.Id : this._globalUnitTPerHa.Id;
      }
    }
    return this._globalUnitKgPerHa.Id;
  }

  public isDataObjectValid(): boolean {
    if (!this.componentData) {
      return false;
    }
    if (this.componentData?.Type === PlanningEntryComponentDataType.Edit) {
      const summary = this.componentData.Summary;
      const planBooks = summary?.BasicFertilisationPlanBooks?.filter(x => !x.DeletedAt && !x.BookedAt) ?? [];
      return planBooks && planBooks.length > 0;
    }
    return true;
  }

  public getTotalSumValueFromConst(goodsAmount: number | null, totalAreaHa: number): number | null {
    if (goodsAmount == null) {
      return null;
    }
    if (!goodsAmount || !totalAreaHa) {
      return 0;
    }
    return goodsAmount * totalAreaHa;
  }

  public getConstValueFromTotalSum(totalSum: number | null, totalAreaHa: number): number | null {
    if (totalSum == null) {
      return null;
    }
    if (!totalSum || !totalAreaHa) {
      return 0;
    }
    return totalSum / totalAreaHa;
  }

  public getModesAndStatusForPlan(selectedField: any, element: number): ModesAndStatusForPlan {
    let operationMode = ApOperationMode.Const;
    let applicationMode = ApApplicationMode.Constant;
    let status = 2; // default: Gelb
    if (this._summaryForCreate.User_Operation_Mode === ApOperationMode.AppMap && selectedField?.Status === 1) {
      const rb = this.rbStore.getRbStatisticByFieldGeomId(selectedField.FieldGeomId.toString());
      if (rb !== null) {
        const avgRB2 = this.nutrientStatisticService.getAverageRb(element + 3, rb);
        if (avgRB2 > this.roundNumericPipe.transform(0, 0)) {
          operationMode = ApOperationMode.AppMap;
          applicationMode = ApApplicationMode.Variable;
          status = 1;
        }
      }
    }
    return {
      ApplicationMode: applicationMode,
      OperationMode: operationMode,
      Status: status
    };
  }

  //#region "private Methods"

  private _revertDeletedData(): void {
    if (this._summaryForEdit && !this._isSaved) {
      if (this._allPlanBooksFromSummaryToEdit) {
        this.baseFertilizationCommonService.revertSummary(this._summaryForEdit);
      } else {
        const planBooksToRevert = this._summaryForEdit.BasicFertilisationPlanBooks.filter(x => !x.DeletedAt && !x.BookedAt);
        this.baseFertilizationCommonService.revertSummaryPlanBooks(planBooksToRevert);
      }
      this.baseFertilizationCommonService.setSelectedSummaryKey(this._summaryForEdit.Id);
      this._summaryForEdit = undefined;
    }
  }

  private _createNewSummary(): void {
    const startYear = this.campaignYearStore.getSelectedCampaignYear().Year;
    const endYear = this.campaignYearStore.IsCurrentCampaignYear ? startYear + 5 : startYear;
    const campaignYearRange = this.campaignYearService.getCampaignYearRange(startYear, endYear);
    this._isNewMode = this.componentData.Type === PlanningEntryComponentDataType.Create;
    if (this.componentData.Summary) {
      this._summaryForEdit = ObjectFactory.Copy(this.componentData.Summary);
      const farmApplDate = this.dateService.toFarmDate(this._summaryForEdit?.User_Appl_Date).toDate();
      const nonDeletedPlanBooks = this._summaryForEdit.BasicFertilisationPlanBooks.filter(x => !x.DeletedAt);
      const nonBookedPlanBooks = nonDeletedPlanBooks.filter(x => !x.BookedAt);
      const planBook = nonBookedPlanBooks[0];
      if (this._selectedFields.length <= 0) {
        const planBookFieldIds = nonBookedPlanBooks.map(x => x.FieldId);
        this.rememberSelectedFields(this.fieldStore.getFieldsById(planBookFieldIds), true);
      }
      this._allPlanBooksFromSummaryToEdit = nonDeletedPlanBooks.length === nonBookedPlanBooks.length;
      this._summaryForCreate = this._generateSummary(this._isNewMode, ApGuidUtil.generateNewGuid(), this._summaryForEdit.Title,
        this._summaryForEdit.FarmId, planBook?.Product_Id, this._summaryForEdit?.User_Appl_Unit?.Id,
        this._summaryForEdit?.User_Appl_Mode.Id, this._summaryForEdit?.User_Appl_Const,
        this._summaryForEdit?.User_Appl_Min, this._summaryForEdit?.User_Appl_Max, this._summaryForEdit?.User_Appl_Rate,
        farmApplDate, this._summaryForEdit?.User_Operation_Mode?.Id, this._summaryForEdit?.PlanNumber,
        planBook.Element?.Id, campaignYearRange.StartYear, campaignYearRange.EndYear, planBook?.ExportDriver,
        planBook.ExportMachine, planBook.ExportInstrument);
    } else {
      this._isNewMode = true;
      const farmApplDate = this.dateService.getDateNoonToFarmTime(this.nutrientManagementDateService.GetOutputDate());
      this._summaryForCreate = this._generateSummary(this._isNewMode, ApGuidUtil.generateNewGuid(), '', this.loginStore.SelectedFarmId,
        0, this._globalUnitKgPerHa.Id, ApApplicationMode.Variable, null, null, null,
        null, farmApplDate, ApOperationMode.AppMap, null, ApElementType.P, campaignYearRange.StartYear,
        campaignYearRange.EndYear, null, null, null);
    }
  }

  private _generateSummary(isNewMode: boolean, id: IGuid, title: string, farmId: number, productId: number | null, applUnit: number,
                           applMode: ApApplicationMode, applConst: number | null, applMin: number | null, applMax: number | null,
                           applRate: number | null, applDate: Date, operationMode: ApOperationMode, planNumber: number | null,
                           element: ApElementType, minDate: Date, maxDate: Date, driverId: IGuid | null, machineId: IGuid | null,
                           instrumentId: IGuid | null): INutrientPlaningSummaryData {
    const groupId = this.fertilizerStore.getFertilizer(productId)?.IsOrganic
      ? ApNutrientGroup.Organic
      : ApNutrientGroup.Mineral;
    const summary: INutrientPlaningSummaryData = {
      Id: id,
      Title: title,
      Farm_Id: farmId,
      Product_Id: productId,
      User_Appl_Unit: applUnit,
      User_Appl_Mode: applMode,
      User_Appl_Const: applConst,
      User_Appl_Min: applMin,
      User_Appl_Max: applMax,
      User_Appl_Date: applDate,
      User_Operation_Mode: operationMode,
      Plan_Number: planNumber,
      Statistic: [],
      Selected_Fields: [],
      TotalFieldsArea: null,
      Element: element,
      Group_Id: groupId,
      User_Operation_Mode_Const_Only: false,
      User_Operation_Mode_Some_Const: false,
      Nutrient_Planing_Data: [],
      RbStatistics: [],
      MinDate: minDate,
      MaxDate: maxDate,
      Driver: this.driverStore.getDriverById(driverId),
      Machine: this.machineStore.getMachineById(machineId),
      Instrument: this.instrumentStore.getInstrumentById(instrumentId),
      Amount_Nutrient: null,
      Amount_Goods: null,
      Amount_Percent: null
    };
    if (!isNewMode) {
      if (this.isKgPerHOrM3PerHOrTPerHUnit(summary.User_Appl_Unit)) {
        summary.Amount_Goods = applRate;
        summary.Amount_Nutrient = this.nutrientService.convertGoodsToNutrient(
          summary.Product_Id, element, applRate, false);
      } else {
        summary.Amount_Percent = applRate;
      }
    }
    return summary;
  }

  private _convertingSummarySaveObject(): IBasicFertilisationSummaries {
    const applicationMode = this.applicationModesStore.Listen(s => s.data)
      .getValue()
      .Find((a) => a.Id === this._summaryForCreate.User_Appl_Mode);
    const operationMode = this.operationModesStore.Listen(s => s.data)
      .getValue()
      .Find((o) => o.Id === this._summaryForCreate.User_Operation_Mode);
    const element = this.elementStore.Listen(s => s.data)
      .getValue()
      .Find((o) => o.Id === this._summaryForCreate.Nutrient_Planing_Data[0].Element);

    const newSummary: IBasicFertilisationSummaries = {
      Id: this._summaryForCreate.Id,
      Title: this._summaryForCreate.Title,
      FarmId: this._summaryForCreate.Farm_Id,
      User_Appl_Min: this._summaryForCreate.User_Appl_Min,
      User_Appl_Max: this._summaryForCreate.User_Appl_Max,
      User_Appl_Unit: this._getSummaryUnit(),
      User_Appl_Mode: applicationMode,
      User_Operation_Mode: operationMode,
      User_Appl_Rate: this._getSummaryApplRate(),
      User_Appl_Const: this._summaryForCreate.User_Appl_Const,
      User_Appl_Date: this.dateService.getDateNoon(this._summaryForCreate.User_Appl_Date),
      Statistic: this._summaryForCreate.Statistic,
      PlanNumber: 0,
      Version: 0,
      CreatedAt: null,
      CreatedBy: null,
      ChangedAt: null,
      ChangedBy: null,
      DeletedAt: null,
      DeletedBy: null,
      BasicFertilisationPlanBooks: []
    };
    const readyPlannings = this._summaryForCreate.Nutrient_Planing_Data.FindAll(x => x.Ready);
    readyPlannings.forEach(item => {
        const geom = this.fieldStore.getCurrentFieldGeom(item.Field);
        const itemApplicationMode = this.applicationModesStore.Listen(s => s.data)
          .getValue()
          .Find((a) => a.Id === item.Appl_Mode);
        const itemOperationMode = this.operationModesStore.Listen(s => s.data)
          .getValue()
          .Find((o) => o.Id === item.Operation_Mode);
        const planning: IBasicFertilisationPlanBooks = {
          Summary_Id: this._summaryForCreate.Id,
          Id: item.Plan_Id,
          Appl_Const: item.Appl_Const,
          Appl_Min: item.Appl_Min,
          Appl_Max: item.Appl_Max,
          Appl_Mode: itemApplicationMode,
          Appl_Rate: item.Appl_Rate,
          Croptype_Id: item.Croptype_Id,
          Croptype_Second_Id: item.Croptype_Second_Id,
          Element: element,
          Factor: item.Factor,
          Note: this._summaryForCreate.Title,
          Operation_Mode: itemOperationMode,
          Unit: this.globalUnitKgPerHa,
          Valid_From: item.Valid_From,
          Valid_To: item.Valid_To,
          Product_Id: this._summaryForCreate.Product_Id,
          FieldId: item.Field_Id,
          Field_Geom_Id: item.Field_Geom_Id,
          Geom: item.Geom,
          Statistic: item.Statistic,
          FarmId: this._summaryForCreate.Farm_Id,
          Area: geom?.AdminArea,
          AreaHa: (geom?.AdminArea > 0 ? geom?.AdminArea : 10000) / 10000,
          ApplicationDate: this.dateService.getDateNoon(item.Appl_Date),
          ExportDriver: item.Driver?.Id,
          ExportInstrument: item.Instrument?.Id,
          ExportMachine: item.Machine?.Id,
          Status: 1,
          Version: 0,
          CreatedAt: null,
          CreatedBy: null,
          ChangedAt: null,
          ChangedBy: null,
          DeletedAt: null,
          DeletedBy: null,
          ExportedAt: null,
          ExportedBy: null,
          ExportFormat: null,
          ExportTarget: null,
          TStart: null,
          TEnd: null,
          BookedBy: null,
          BookedAt: null,
          JobInput: ''
        };
        newSummary.BasicFertilisationPlanBooks.Add(planning);
      }
    );
    return newSummary;
  }

  private _getSummaryUnit(): IUnit {
    const productId = this._summaryForCreate.Product_Id;
    const inputUnit = this._summaryForCreate.User_Appl_Unit;
    // Prozenteingabe
    if (inputUnit === 3) {
      return this.unitStore.Listen(s => s.data).getValue().Find((o) => o.Id === inputUnit);
    }
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    if (!!fertilizer) {
      const unit = this.unitStore.Listen(s => s.data).getValue().Find((o) => o.BaseUnit === fertilizer.Unit);
      if (!!unit) {
        return unit;
      }
    }
    // Fallback
    return this.unitStore.Listen(s => s.data).getValue().Find((o) => o.Id === inputUnit);
  }

  private _getSummaryApplRate(): number {
    const isPhysicalAmount = this.isKgPerHOrM3PerHOrTPerHUnit(this._summaryForCreate.User_Appl_Unit);
    const goodsAmount = this._summaryForCreate.Amount_Goods;
    const percent = this._summaryForCreate.Amount_Percent;
    return isPhysicalAmount ? goodsAmount : percent;
  }

  //#endregion "private Methods"
}
