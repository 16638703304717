<ng-scrollbar *ngIf="visiblePanel; else apMenu" track="vertical" [autoHeightDisabled]="false" style="height:1000px" [sensorDebounce]="100">
  <ng-template [ngTemplateOutlet]="apMenu"></ng-template>
</ng-scrollbar>
<ng-template #apMenu>
  <div class="ap-menu-wrapper">
    <!--toggle for big and collapsed menu-->
    <div class="ap-sidebar-toggle" (click)="toggleSidebar()" style="grid-row:2">
      <span class="k-icon k-i-arrow-60-left" [@rotateIcon]="(sidebarMode$ | async)"></span>
    </div>
    <div [ngClass]="visiblePanel ? 'ap-menu' : (this.userSelectedUrl === '' ? 'ap-menu ap-menu-collapsed':'ap-menu ap-menu-collapsed-hovered ap-menu-collapsed')">
      <!-- main menu -->
      <kendo-panelbar (stateChange)="menuStateChange($event)" [animate]="visiblePanel"
                      [expandMode]="panelBarExpandMode.Single" style="grid-row:1"
                      [selectable]="false" style="margin-bottom: auto" class="ap-menu-main-panelbar">
        <kendo-panelbar-item *ngFor="let main of menuData | async; trackBy: menuTrackBy" title=""
                             (mouseenter)="expandItem(main)"
                             (mouseleave)="expandItem(null)"
                             [disabled]="!main.Enabled"
                             [id]="main.Url"
                             [expanded]="main | isMainExpanded:(url$ | async | removeUrlParams) : (pageViewMode$ | async) : visiblePanel : userSelectedUrl"
                             [selected]="main | isMainSelected:(url$ | async | removeUrlParams) : userSelectedUrl"
                             [ngClass]="(main | isMainSelected:(url$ | async | removeUrlParams) : userSelectedUrl) ? 'ap-menu-title-selected' : ''">
          <ng-template kendoPanelBarItemTitle>
            <div class="ap-menu-main" (click)="navigate(main)">
              <ap-icon-selector [id]="main.IconClass"></ap-icon-selector>
              <span [class.ap-menu-title]="!main.IsGroup" [class.ap-menu-title-group]="main.IsGroup"  class="ap-menu-title"
                    [id]="main.Url | getReplace: '/' : '_'">{{main.Title | translate}}</span>
            </div>
          </ng-template>

          <!-- main menu children-->
          <kendo-panelbar-item *ngFor="let sub of main.Childs; let i = index" title=""
                               [disabled]="!sub.Enabled"
                               [id]="sub.Url"
                               [selected]="sub ? sub.Url === (url$ | async | removeUrlParams) : false"
                               (click)="navigate(sub)">
            <ng-template kendoPanelBarItemTitle>
              <div class="ap-menu-sub">
                <span  [id]="sub.Url | getReplace: '/' : '_'">{{sub.Title | translate}}</span>
              </div>
            </ng-template>
          </kendo-panelbar-item>

        </kendo-panelbar-item>
      </kendo-panelbar>
      <!--<div style="flex-basis: 0; flex-grow: 1"></div>-->
      <!--bottom menu-->
      <kendo-panelbar (stateChange)="menuStateChange($event)" [animate]="visiblePanel"
                      [expandMode]="panelBarExpandMode.Single" style="margin-top: auto" class="ap-menu-info-panelbar">
        <kendo-panelbar-item *ngFor="let bottom of bottomMenuData | async; trackBy: menuTrackBy" title=""
                             (mouseenter)="expandItem(bottom)"
                             (mouseleave)="expandItem(null)"
                             [disabled]="!bottom.Enabled"
                             [routerLinkActive]=""
                             [id]="bottom.Url"
                             [expanded]="bottom | isMainExpanded:(url$ | async | removeUrlParams) : (pageViewMode$ | async) : visiblePanel : userSelectedUrl"
                             [selected]="bottom | isMainSelected:(url$ | async | removeUrlParams) : userSelectedUrl"
                             [ngClass]="(bottom | isMainSelected:(url$ | async | removeUrlParams) : userSelectedUrl) ? 'ap-menu-title-selected' : ''">
          <ng-template kendoPanelBarItemTitle>
            <div class="ap-menu-info ap-menu-main" (click)="navigate(bottom)">
              <ap-icon-selector [id]="bottom.IconClass"></ap-icon-selector>
              <span class="ap-menu-title"
                    [id]="bottom.Url | getReplace: '/' : '_'">{{bottom.Title | translate}}</span>
            </div>
          </ng-template>

          <!-- bottom menu children-->
          <kendo-panelbar-item *ngFor="let sub of bottom.Childs; let i = index" title=""
                               [disabled]="!sub.Enabled"
                               [id]="sub.Url"
                               [selected]="sub ? sub.Url === (url$ | async | removeUrlParams) : false"
                               (click)="navigate(sub)">
            <ng-template kendoPanelBarItemTitle>
              <div class="ap-menu-sub">
                <span  [id]="sub.Url | getReplace: '/' : '_'">{{sub.Title | translate}}</span>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar-item>
      </kendo-panelbar>
    </div>
  </div>
</ng-template>
