import {IAction, IApInvokerArguments} from '../../interfaces';

export enum NApplicationMapsActions {
  APPLICATION_SETTINGS_LOAD = '[application_settings] load',
  APPLICATION_SETTINGS_LOAD_SUCCESS = '[application_settings] load success',
  APPLICATION_SETTINGS_LOAD_FAIL = '[application_settings] load fail'
}

/**
 * load data from backend
 */
export class ApplicationSettingsLoad implements IAction {
  readonly type = NApplicationMapsActions.APPLICATION_SETTINGS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * load data from backend success
 */
export class ApplicationSettingsLoadSuccess implements IAction {
  readonly type = NApplicationMapsActions.APPLICATION_SETTINGS_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * load data from backend success
 */
export class ApplicationSettingsLoadFail implements IAction {
  readonly type = NApplicationMapsActions.APPLICATION_SETTINGS_LOAD_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}
