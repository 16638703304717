import {AfterContentInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Validators}                                                from '@angular/forms';
import {BehaviorSubject, combineLatest, Subscription}              from 'rxjs';
import {filter, map, switchMap}                                    from 'rxjs/operators';
import {ObjectFactory}                                             from 'ts-tooling';
import {
  ApDynformsConfigFieldset
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigTextbox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApDynformsValidator}                                       from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDynformsConfigComboBox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynformsConfigCheckbox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {
  ApDynformsConfigNumerictextbox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {ApDynformsValidations}                                     from '../../ap-dynforms/ap-dynforms-validations';
import {
  FertilizerOrganicStore
}                                                                  from '../../stores/base-data/fertilizer.organic.store';
import {FormStore}                                                 from '../../stores/layout/form.store';
import {LoginStore}                                                from '../../stores/login/login.store';
import {FactorService}                                             from '../../ap-utils/service/ap-factor.service';
import {UnitsStore}                                                from '../../stores/common/units.store';
import {
  ApDynformsConfigPlaceholder
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-placeholder';
import {ApTranslationService}                                      from '../../ap-utils/service/ap-translation.service';
import {ModalDialogStore}                                          from '../../stores/dialog/modal.dialog.store';
import {
  IModalDialogData,
  ModalDialogButtonDisable
}                                                                  from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {FertilizerStore}                                           from '../../stores/base-data/fertilizer.store';
import {
  ApDynComponentComponent
}                                                                  from '../../ap-dyncomponent/ap-dyncomponent.component';
import IUnit = Data.Common.IUnit;
import IFertilizerInorg = Data.BaseData.IFertilizerInorg;

/**
 * Fertilizer Orgs component.
 */
@Component({
  selector: 'ap-fertilizer-orgs-edit',
  template: `
    <ap-dyncomponent [fieldSets]="formConfig" [caption]="caption" [loading$]="loading$">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_fertilizer_org_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{ 'Global__Cancel' | translate }}
        </button>
        <button id="button_fertilizer_org_edit_save"
                type="button" [disabled]="saveButtonDisabled$ | async"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{ 'Global__Save' | translate }}
        </button>
      </div>
    </ap-dyncomponent>`
})

/**
 * Fertilizer Orgs edit component.
 */
export class ApFertilizerOrgsEditComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(ApDynComponentComponent, {static: true}) dynComponent: ApDynComponentComponent;
  public data: any;
  public formConfig: ApDynformsConfigFieldset[];
  public isNewMode = false;
  public units: IUnit[];
  public uniqueNames: any[];
  public format = 'n2';
  public caption: string;
  public loading$ = new BehaviorSubject<boolean>(true);
  public saveDisabled$ = new BehaviorSubject<boolean>(false);
  public saveButtonDisabled$ = new BehaviorSubject<boolean>(false);

  private _subscriptions: Array<Subscription> = [];
  private _isLock = false;

  constructor(private formStore: FormStore,
              private fertilizerStore: FertilizerStore,
              private fertilizerOrganicStore: FertilizerOrganicStore,
              private loginStore: LoginStore,
              private factorService: FactorService,
              private unitStore: UnitsStore,
              private translationService: ApTranslationService,
              private modalDialogStore: ModalDialogStore) {
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        Id: '',
        FarmId: this.loginStore.SelectedFarmId,
        Prodname: null,
        CategoryId: 1,
        Note: null,
        Selectable: true,
        IsLiquid: false,
        ContentCaO: 0,
        ContentK: 0,
        ContentMg: 0,
        ContentN: 0,
        ContentP: 0,
        DMPercent: 0,
        Efficiency: 100,
        Density: 1,
        Unit: null,
        RealContentCaO: 0,
        AlkalineEff: false,
        AlkalineCaO: 0,
        ContentCaCO3: 0,
        ContentMgO: 0,
        ContentP2O5: 0,
        ContentK2O: 0
      };
    } else {
      this.isNewMode = false;
      this.data.ContentCaO = this.data.RealContentCaO;
      this.fertilizerStore.loadFertilizerIsInUse(this.data.Id);
    }
    this._getDataFromStore();
    this.caption = this.isNewMode ? 'Settings__Lbl_Fertilizer_Create' : 'Settings__Lbl_Fertilizer_Edit';
    this.units = this.unitStore.Listen(s => s.data).getValue();
    this.uniqueNames = this.fertilizerOrganicStore.getFertilizerOrganic().map((n) => ({Name: n.Prodname}));
    if (!this.isNewMode) {
      this.uniqueNames = this.uniqueNames.filter((uN) => uN.Name !== this.data.Prodname);
    }
    this.data.Unit = this.unitStore.getUnit(this.data.IsLiquid ? 'm³' : 't');
    this._formBuilder();
    const self = this;
    this.dynComponent.dynForm.initialized.subscribe(() => {
      if (!this.isNewMode) {
        this.dynComponent.dynForm.form.controls['ContentP2O5'].setValue(this.factorService.calculate(this.data.ContentP, 'P', 'P2O5'));
        this.dynComponent.dynForm.form.controls['ContentK2O'].setValue(this.factorService.calculate(this.data.ContentK, 'K', 'K2O'));
        this.dynComponent.dynForm.form.controls['ContentMgO'].setValue(this.factorService.calculate(this.data.ContentMg, 'Mg', 'MgO'));
        this.dynComponent.dynForm.form.controls['ContentCaCO3'].setValue(this.factorService.calculate(this.data.ContentCaO, 'CaO', 'CaCO3'));
        if (this.data.AlkalineEff) {
          const tmp = this.dynComponent.dynForm.form.get('ContentMgO').value * 1.391;
          const cal = this.data.ContentCaO + tmp;
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(cal);
        }
      }
      this.dynComponent.dynForm.form.get('ContentP').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'P', 'P2O5');
      });
      this.dynComponent.dynForm.form.get('ContentP2O5').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'P2O5', 'P');
      });
      this.dynComponent.dynForm.form.get('ContentK').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'K', 'K2O');
      });
      this.dynComponent.dynForm.form.get('ContentK2O').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'K2O', 'K');
      });
      this.dynComponent.dynForm.form.get('ContentMg').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'Mg', 'MgO');
      });
      this.dynComponent.dynForm.form.get('ContentMgO').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'MgO', 'Mg');
        if (this.dynComponent.dynForm.form.get('AlkalineEff').value) {
          const tmp = c * 1.391;
          const cal = this.dynComponent.dynForm.form.get('ContentCaO').value + tmp;
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(cal);
        }
      });
      this.dynComponent.dynForm.form.get('ContentCaO').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'CaO', 'CaCO3');
        if (this.dynComponent.dynForm.form.get('AlkalineEff').value) {
          const tmp = this.dynComponent.dynForm.form.get('ContentMgO').value * 1.391;
          const cal = c + tmp;
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(cal);
        }
        if (c < 0) {
          this.dynComponent.dynForm.form.get('AlkalineEff').setValue(false, {emitEvent: false});
        }
      });
      this.dynComponent.dynForm.form.get('ContentCaCO3').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'CaCO3', 'CaO');
        if (c < 0) {
          this.dynComponent.dynForm.form.get('AlkalineEff').setValue(false, {emitEvent: false});
        }
      });
      this.dynComponent.dynForm.form.get('AlkalineEff').valueChanges.subscribe((c) => {
        if (c) {
          const tmp = this.dynComponent.dynForm.form.get('ContentMgO').value * 1.391;
          const cal = this.dynComponent.dynForm.form.get('ContentCaO').value + tmp;
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(cal);
        } else {
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(0);
        }
      });
      this.dynComponent.dynForm.form.get('UnitId').valueChanges.subscribe(_ => {
        self.formConfig.forEach(fs => {
          if (fs.legend === 'Global__Content' || fs.legend === 'Global__Basic_Effect') {
            fs.config.forEach((configBase) => {
              if (configBase.key.indexOf('Content') >= 0 || configBase.key === 'AlkalineCaO') {
                let newLabel = configBase.label.slice(0, configBase.label.indexOf('/') + 1);
                newLabel += self.data.Unit.Description + ']';
                configBase.label = newLabel;
              }
            });
          }
        });
      });
      this.dynComponent.dynForm.form.get('UnitId').setValue(this.data.Unit.Id);
      this.dynComponent.dynForm.form.get('IsLiquid').valueChanges.subscribe((c) => {
        if (c) {
          this.data.Unit = this.unitStore.getUnit('m³');
        } else {
          this.data.Unit = this.unitStore.getUnit('t');
        }
        this.dynComponent.dynForm.form.controls['UnitId'].setValue(this.data.Unit.Id);
      });
      this._subscriptions.push(combineLatest([
        this.dynComponent.dynForm.form$.pipe(
          filter((form) => form != null),
          switchMap((form) => form.statusChanges),
          map((status) => status !== 'VALID')
        ), this.saveDisabled$])
        .subscribe((status) => {
          this.dynComponent.dynForm.form.markAllAsTouched();
          this.saveButtonDisabled$.next(status.Any((s) => s));
        })
      );
    });
  }

  ngAfterContentInit(): void {
    setTimeout(() => this.saveButtonDisabled$.next(!this.dynComponent.dynForm.form?.valid));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => d.unsubscribe());
  }

  /**
   * Cancel clicked eventHandler - closes form.
   */
  public onCancelClick(): void {
    this.formStore.closeForm();
  }

  /**
   * Submitting the changes.
   * Either creating a new object or updating the current.
   */
  public onSubmitClick(): void {
    ObjectFactory.Merge(this.data, this.dynComponent.dynForm.form.value);
    if (this.data.IsLiquid === '') {
      this.data.IsLiquid = false;
    }

    if (this.data.IsLiquid) {
      this.data.Unit = this.unitStore.getUnit('m³');
    } else {
      this.data.Unit = this.unitStore.getUnit('t');
    }

    if (this.isNewMode) {
      this.data.FarmId = this.loginStore.SelectedFarmId;
      this.data.Id = 0;
      this.fertilizerOrganicStore.createFertilizerOrganic(this.data);
    } else {
      if (this.data.FarmId !== this.loginStore.SelectedFarmId) {
        this.data.FarmId = this.loginStore.SelectedFarmId;
        this.data.Id = 0;
      }
      this.fertilizerOrganicStore.updateFertilizerOrganic(this.data);
    }
    this.formStore.closeForm();
  }

  private _showMessage(message: string): void {
    this.modalDialogStore.setModalDialogData({
      formConfig: undefined,
      show: true,
      buttons: [
        {
          disable: ModalDialogButtonDisable.Never,
          key: 'Y',
          primary: true,
          text: 'Global__Ok'
        },
      ],
      title: this.translationService.translate('Global__Info'),
      message: this.translationService.translate(message)
    } as IModalDialogData);
    this.saveDisabled$.next(true);
  }

  private _calculateFormValue(value: number, source: string, target: string): void {
    if (this._isLock) {
      return;
    }
    this._isLock = true;
    let next = this.factorService.calculate(value, source, target);
    if (next < 0 && source !== 'CaO' && target !== 'CaO') {
      next = 0;
    }
    const targetString = 'Content' + target;
    this.dynComponent.dynForm.form.get(targetString).setValue(next, {emitEvent: false});
    setTimeout(() => this._isLock = false, 1);
  }

  private _getDataFromStore(): void {
    if (!this.isNewMode) {
      this._subscriptions.push(this.fertilizerStore.Listen(s => s.CountInUse).subscribe(s => {
        if (s !== null) {
          this.loading$.next(false);
          if (s > 0) {
            setTimeout(() => this._showMessage('Global__Fertilizer_Used_Plan_Or_Book_Not_Changed'));
          }
          if (this.data.FarmId === -1) {
            setTimeout(() => this._showMessage('Global__System_Fertilizer_Can_Not_Processed'));
          }
        }
      }));
    } else {
      this.loading$.next(false);
    }
  }

  /**
   * binding of validators
   */
  private _formBuilder(): void {
    this.formConfig = [this._getFertilizerFieldset(), this._getConstantFieldset(), this._getAlkalineFieldset()];
  }

  private _getLabelWithUnit(text: string, unit: string): string {
    return this.translationService.translate(text) + ' [' + unit + ']';
  }

  private _getFertilizerFieldset(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'fertilizer1',
      config: [
        new ApDynformsConfigTextbox({
          key: 'Prodname',
          label: 'Global__Name',
          value: this.data.Prodname,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Fill_Name',
              always: true
            }),
            new ApDynformsValidator({
              validator: Validators.minLength(3),
              errorKey: 'Settings__Msg_Vali_Biggerthree_Short'
            }),
            new ApDynformsValidator({
              validator: ApDynformsValidations.validateList(this.uniqueNames),
              errorKey: 'Settings__Msg_Vali_DoubleName'
            })
          ]
        }),
        new ApDynformsConfigCheckbox({
          key: 'IsLiquid',
          label: 'Liquid',
          value: this.data.IsLiquid,
        }),
        new ApDynformsConfigTextbox({
          key: 'Note',
          label: 'Global__Note',
          value: this.data.Note
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'DMPercent',
          label: this._getLabelWithUnit('Global__Drymatter', '%'),
          infoText: 'Global__Drymatter_Info',
          value: this.data.DMPercent,
          format: this.format,
          min: 0,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigComboBox({
          key: 'UnitId',
          value: this.data.Unit != null ? this.data.Unit.Id : this.units.FirstOrDefault().Id,
          valueField: 'Id',
          textField: 'Description',
          label: 'Settings__Lbl_Menu_Unit',
          options: this.units,
          valuePrimitive: true,
          disabled: true,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
      ]
    });
  }

  private _getConstantFieldset(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'fertilizer2',
      legend: 'Global__Content',
      config: [
        new ApDynformsConfigNumerictextbox({
          key: 'ContentN',
          label: this._getLabelWithUnit('Global__NutrientN', 'kg/m³'),
          value: this.data.ContentN || 0,
          format: this.format,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'Efficiency',
          label: this._getLabelWithUnit('Settings__Lbl_NEffectiveness', '%'),
          value: this.data.Efficiency,
          format: 'n0',
          decimals: 0,
          min: 0,
          max: 100,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentS',
          label: this._getLabelWithUnit('Global__NutrientS', 'kg/m³'),
          value: this.data.ContentS || 0,
          format: this.format,
          min: 0,
          max: 1000,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigPlaceholder(),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentP',
          label: this._getLabelWithUnit('Nutrients__P', 'kg/m³'),
          value: this.data.ContentP || 0,
          format: this.format,
          min: 0,
          max: 1000,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentP2O5',
          label: this._getLabelWithUnit('Global__NutrientP2O5', 'kg/m³'),
          value: this.data.ContentP2O5 || 0,
          format: this.format,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentK',
          label: this._getLabelWithUnit('Global__NutrientK', 'kg/m³'),
          value: this.data.ContentK || 0,
          format: this.format,
          min: 0,
          max: 1000,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentK2O',
          label: this._getLabelWithUnit('Global__NutrientK2O', 'kg/m³'),
          value: this.data.ContentK2O || 0,
          format: this.format,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentMg',
          label: this._getLabelWithUnit('Global__NutrientMg', 'kg/m³'),
          value: this.data.ContentMg || 0,
          format: this.format,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentMgO',
          label: this._getLabelWithUnit('Global__NutrientMgO', 'kg/m³'),
          value: this.data.ContentMgO || 0,
          format: this.format,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentCaO',
          label: this._getLabelWithUnit('Global__NutrientCaO', 'kg/m³'),
          value: this.data.ContentCaO || 0,
          infoText: 'Info__CaO',
          format: this.format,
          min: -100,
          max: 100,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentCaCO3',
          label: this._getLabelWithUnit('Global__Unit_CaCO3', 'kg/m³'),
          value: this.data.ContentCaCO3 || 0,
          format: this.format,
          min: this.factorService.calculate(-100, 'CaO', 'CaCO3'),
          max: this.factorService.calculate(100, 'CaO', 'CaCO3'),
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigCheckbox({
          key: 'AlkalineEff',
          infoText: 'Info__Alkaline_Effective',
          label: 'Global__Alkaline_Effective',
          value: this.data.AlkalineEff,
          disabled$: this.dynComponent.FormValues$.pipe(map((v) => v['ContentCaO'] < 0))
        }),
      ]
    });
  }

  private _getAlkalineFieldset(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'fertilizer3',
      legend: 'Global__Basic_Effect',
      config: [
        new ApDynformsConfigNumerictextbox({
          key: 'ContentNeutralisationValue',
          infoText: 'Info__Neutralisation_Value',
          label: this._getLabelWithUnit('Global__Neutralisation_Value', 'kg/m³'),
          value: this.data.ContentCaO + (this.data.ContentMgO * 1.391),
          format: this.format,
          disabled$: this.dynComponent.FormValues$.pipe(map((v: IFertilizerInorg) => {
            return !v.AlkalineEff || v.AlkalineCaO > 0;
          })),
          readonly: true
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'AlkalineCaO',
          infoText: 'Info_Alkaline_Effective_Content',
          label: this._getLabelWithUnit('Global__Alkaline_Effective_Ingredient', 'kg/m³'),
          value: this.data.AlkalineCaO,
          format: this.format,
          min: 0,
          disabled$: this.dynComponent.FormValues$.pipe(map((v: IFertilizerInorg) => {
            return !v.AlkalineEff;
          }))
        }),
      ]
    });
  }
}
