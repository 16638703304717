import {Injectable}                                                     from '@angular/core';
import {map}                                                            from 'rxjs/operators';
import {FormGroup, ValidatorFn, Validators}                             from '@angular/forms';
import {BehaviorSubject, combineLatest, Subscription}                   from 'rxjs';
import {
  ApOperationMode
}                                                                       from '../../../ap-interface/enums/ap-operation-mode.enum';
import {
  ApDynformsConfigFieldset
}                                                                       from '../../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsValidator
}                                                                       from '../../../ap-dynforms/ap-dynforms-validator';
import {
  ApNumberRangeValidator
}                                                                       from '../../../ap-dynforms/validators/ap-number-range-validator';
import {
  ApDynformsConfigTextbox
}                                                                       from '../../../ap-dynforms/config/ap-dynforms-config-textbox';
import {
  ApDynformsConfigComboBox
}                                                                       from '../../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynformsConfigPlaceholder
}                                                                       from '../../../ap-dynforms/config/ap-dynforms-config-placeholder';
import {
  ApDynformsConfigDatepicker
}                                                                       from '../../../ap-dynforms/config/ap-dynforms-config-datepicker';
import {
  ApNutrientGroup
}                                                                       from '../../../ap-interface/enums/ap-nutrient-groups.enum';
import {
  ApDynformsConfigNumerictextbox
}                                                                       from '../../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {
  ApElementType
}                                                                       from '../../../ap-interface/enums/ap-elements-type.enum';
import {
  GetRoundNumericService
}                                                                       from '../../../ap-utils/service/get-round-numeric.service';
import {FieldStore}                                                     from '../../../stores/farm/field.store';
import {
  FertilizerStore
}                                                                       from '../../../stores/base-data/fertilizer.store';
import {ElementsStore}                                                  from '../../../stores/common/elements.store';
import {
  OperationModesEnum,
  OperationModesStore
}                                                                       from '../../../stores/common/operation-modes.store';
import {
  GetElementService
}                                                                       from '../../../ap-utils/service/ap-get-element.service';
import {NutrientPlanningEntryService}                                   from './nutrient-planning-entry.service';
import {INutrientPlaningSummaryData, INutrientPlanningEntryStepsConfig} from './nutrient-planning-types';
import {DriverStore}                                                    from '../../../stores/docu/driver.store';
import {InstrumentStore}                                                from '../../../stores/docu/instrument.store';
import {MachineStore}                                                   from '../../../stores/docu/machine.store';
import {
  ApNutrientService
}                                                                       from '../../../ap-utils/service/ap-nutrient.service';
import {
  ApDynComponentComponent
}                                                                       from '../../../ap-dyncomponent/ap-dyncomponent.component';
import {
  ApApplicationMode
}                                                                       from '../../../ap-interface/enums/ap-application-mode.enums';
import {
  NutrientManagementService
}                                                                       from '../../../nutrient-management/service/nutrient-management.service';
import {
  ApNutrientWizardStep
}                                                                       from '../../../ap-interface/enums/ap-nutrient-wizard-setup.enums';
import {
  StatisticStore
}                                                                       from '../../../stores/statistic/statistic.store';
import {CombinedFertilizer}                                             from '../../../types/common-types';
import {SettingsStore}                                                  from '../../../stores/base-data/settings.store';
import {UnitService}                                                    from '../../../services/data/unit.service';
import {
  ApDateMinMaxValidator
}                                                                       from '../../../ap-dynforms/validators/ap-date-min-max-validator';
import {
  LanguageStore
}                                                                       from '../../../stores/translation/language.store';
import {ApplicationModesEnum}                                           from '../../../stores/common/appl-modes.store';
import ISettings = Data.BaseData.ISettings;
import IElement = Data.Common.IElement;

@Injectable()
export class NutrientPlanningEntryDetailsStepService implements INutrientPlanningEntryStepsConfig {
  public fieldSetsUpdate$ = new BehaviorSubject<ApDynformsConfigFieldset[]>([]);

  private _dynComponent: ApDynComponentComponent;
  private _isLock = false;
  private _settings: ISettings;
  private _elements: IElement[] = [];
  private _changedProductDropdownContent = new BehaviorSubject(0);
  private _isConstFieldDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _nutrientUnitFertilizerLabel$: BehaviorSubject<string>;
  private _amountUnitFertilizerLabel$: BehaviorSubject<string>;
  private _constantUnitFertilizerLabel$: BehaviorSubject<string>;
  private _totalSumUnitFertilizerLabel$: BehaviorSubject<string>;
  private _minimumUnitFertilizerLabel$: BehaviorSubject<string>;
  private _maximumUnitFertilizerLabel$: BehaviorSubject<string>;
  private _subscriptions: Subscription[] = [];

  constructor(private fieldStore: FieldStore,
              private driverStore: DriverStore,
              private machineStore: MachineStore,
              private elementStore: ElementsStore,
              private settingsStore: SettingsStore,
              private statisticStore: StatisticStore,
              private instrumentStore: InstrumentStore,
              private fertilizerStore: FertilizerStore,
              private operationModesStore: OperationModesStore,
              private unitService: UnitService,
              private elementService: GetElementService,
              private nutrientService: ApNutrientService,
              private roundNumericService: GetRoundNumericService,
              private planningEntryService: NutrientPlanningEntryService,
              private nutrientManagementService: NutrientManagementService,
              private languageStore: LanguageStore) {
  }

  public initData(): void {
    this._nutrientUnitFertilizerLabel$ = new BehaviorSubject(this.planningEntryService.getElementLabelTranslation());
    this._amountUnitFertilizerLabel$ = new BehaviorSubject(this.unitService.getUnitWithLabelKey(null, 'Nutrients__Average_Amount_Goods'));
    this._constantUnitFertilizerLabel$ = new BehaviorSubject(this.unitService.getUnitWithLabelKey(null, 'Global__Const_Goods'));
    this._totalSumUnitFertilizerLabel$ = new BehaviorSubject(this.unitService.getUnitWithLabelKey(null, 'Global__Sum_Goods', false));
    this._minimumUnitFertilizerLabel$ = new BehaviorSubject(this.unitService.getUnitWithLabelKey(null, 'Global__Minimum_Goods'));
    this._maximumUnitFertilizerLabel$ = new BehaviorSubject(this.unitService.getUnitWithLabelKey(null, 'Global__Maximum_Goods'));
    this._settings = this.settingsStore.FirstSetting;
    this._elements = this.elementStore.Elements.filter(x => x.ShortDescription !== 'N');
  }

  public setSubscriptions(dynComponent: ApDynComponentComponent): void {
    this._dynComponent = dynComponent;
    this._subscriptions.push(this._dynComponent.dynForm.formValueChanges.subscribe(e => {
      this._onDetailsFormValueChanges(e.control, e.value);
    }));
    this._subscriptions.push(this.planningEntryService.activeWizardStep.subscribe(step => {
      if (step === ApNutrientWizardStep.Details) {
        this.statisticStore.removeStatisticData();
        this._setConstInputFieldAvailability();
      }
    }));
  }

  public clearData(): void {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  public getDetailFieldsetControls(): ApDynformsConfigFieldset[] {
    if (this.planningEntryService.summary.User_Operation_Mode_Const_Only) {
      this.planningEntryService.summary.User_Operation_Mode = ApOperationMode.Const;
      this.planningEntryService.summary.User_Appl_Mode = ApApplicationMode.Constant;
    }
    this._changedProductDropdownContent.next(0);
    if (this.planningEntryService.summary.User_Operation_Mode === ApOperationMode.AppMap) {
      return [
        this._informationFieldSet(),
        this._generalFieldSet(),
        this._productFieldSet(),
        this._agronomyFieldSet(),
        this._minMaxConstFieldSet(),
        this._documentFiledSet()
      ];
    } else {
      return [
        this._informationFieldSet(),
        this._generalFieldSet(),
        this._productFieldSet(),
        this._agronomyFieldSet(),
        this._documentFiledSet()
      ];
    }
  }

  public afterFieldsetInit(): void {
    if (this.planningEntryService.summary && this.planningEntryService.summary.Product_Id) {
      this._setFormLabels(this.planningEntryService.summary.Product_Id);
    }
  }

  public getFormValidators(): ApDynformsValidator<ValidatorFn>[] {
    return [
      new ApDynformsValidator({
        validator: (frm: FormGroup): { mismatch: boolean } | null => {
          const userApplRate = frm.get('Amount_Nutrient')?.value;
          const amountNutrients = frm.get('Amount_Goods')?.value;
          const amountPercent = frm.get('Amount_Percent')?.value;
          if (!userApplRate && !amountNutrients && !amountPercent) {
            return {mismatch: true};
          }
          return null;
        },
        errorKey: 'Settings__Msg_Vali_Value_Required'
      }),
      new ApDynformsValidator({
        validator: ApNumberRangeValidator.ValidateNumberRange('User_Appl_Min', 'User_Appl_Const'),
        errorKey: 'Text_ValueInvalid'
      }),
      new ApDynformsValidator({
        validator: ApNumberRangeValidator.ValidateNumberRange('User_Appl_Const', 'User_Appl_Max'),
        errorKey: 'Text_ValueInvalid'
      }),
      new ApDynformsValidator({
        validator: ApNumberRangeValidator.ValidateNumberRange('User_Appl_Min', 'Amount_Goods'),
        errorKey: 'Text_ValueInvalid'
      }),
      new ApDynformsValidator({
        validator: ApNumberRangeValidator.ValidateNumberRange('Amount_Goods', 'User_Appl_Max'),
        errorKey: 'Text_ValueInvalid'
      }),
      new ApDynformsValidator({
        validator: ApNumberRangeValidator.ValidateNumberRange('User_Appl_Min', 'User_Appl_Max'),
        errorKey: 'Text_ValueInvalid'
      })
    ];
  }

  //#region "Field Sets"

  private _informationFieldSet(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'Global__Information',
      legend: 'Global__Information',
      config: [
        new ApDynformsConfigTextbox({
          key: 'AgriConnect__Count_Fields',
          label: 'AgriConnect__Count_Fields',
          disabled: true,
          value: this.planningEntryService.summary.Selected_Fields.length
        }),
        new ApDynformsConfigTextbox({
          key: 'Nutrients__TotalArea_ha',
          label: 'Nutrients__TotalArea_ha',
          disabled: true,
          value: this.roundNumericService.round(this.planningEntryService.summary.Selected_Fields
              .Reduce((target, e) => (this.fieldStore.getCurrentFieldGeom(e?.SourceItem)?.AreaHa ?? 0) + target, 0),
            this._settings.DigitsAfterDecimalPoint ?? 0)
        }),
      ],
    });
  }

  private _generalFieldSet(): ApDynformsConfigFieldset {
    const dateFormat = this.languageStore.Listen(l => l.selectedLanguage).getValue().DateFormat;
    if (this.planningEntryService.summary.User_Operation_Mode === ApOperationMode.Const) {
      return new ApDynformsConfigFieldset({
        key: 'Global__General_Information',
        legend: 'Global__General_Information',
        config: [
          new ApDynformsConfigComboBox({
            key: 'User_Operation_Mode',
            label: 'AgriConnect_Pages_Popups__Distribution',
            value: this.planningEntryService.summary.User_Operation_Mode,
            valueField: 'OperationModeId',
            textField: 'ApplicationModeKey',
            translate: true,
            options: this._getValuesForTypeDropDown(),
            disabled: this.planningEntryService.summary.User_Operation_Mode_Const_Only,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
            ],
            valuePrimitive: true
          }),
          new ApDynformsConfigPlaceholder(),
          new ApDynformsConfigDatepicker({
            key: 'User_Appl_Date',
            label: 'Nutrients__FertilizationDate',
            value: this.planningEntryService.summary.User_Appl_Date,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: ApDateMinMaxValidator.ValidateDateRange(this.planningEntryService.summary.MinDate, this.planningEntryService.summary.MaxDate),
                errorKey: ApDateMinMaxValidator.getYearRangeFormatted(dateFormat, this.planningEntryService.summary.MinDate, this.planningEntryService.summary.MaxDate)
              })
            ],
            minDate: this.planningEntryService.summary.MinDate,
            maxDate: this.planningEntryService.summary.MaxDate,
            infoText: ''
          }),
          new ApDynformsConfigTextbox({
            key: 'Title',
            label: 'Global__Note',
            value: this.planningEntryService.summary.Title,
          })
        ],
      });
    } else {
      return new ApDynformsConfigFieldset({
        key: 'Global__General_Information',
        legend: 'Global__General_Information',
        config: [
          new ApDynformsConfigComboBox({
            key: 'User_Operation_Mode',
            label: 'AgriConnect_Pages_Popups__Distribution',
            value: this.planningEntryService.summary.User_Operation_Mode,
            valueField: 'OperationModeId',
            textField: 'ApplicationModeKey',
            translate: true,
            options: this._getValuesForTypeDropDown(),
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
            ],
            valuePrimitive: true
          }),
          new ApDynformsConfigComboBox({
            key: 'Element',
            label: 'AgriConnect__Nutrient',
            value: this.planningEntryService.summary.Element,
            textField: 'Key',
            valueField: 'Id',
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
            ],
            options: this._elements,
            translate: true,
            valuePrimitive: true,
          }),
          new ApDynformsConfigDatepicker({
            key: 'User_Appl_Date',
            label: 'Nutrients__FertilizationDate',
            value: this.planningEntryService.summary.User_Appl_Date,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: ApDateMinMaxValidator.ValidateDateRange(this.planningEntryService.summary.MinDate, this.planningEntryService.summary.MaxDate),
                errorKey: ApDateMinMaxValidator.getYearRangeFormatted(dateFormat, this.planningEntryService.summary.MinDate, this.planningEntryService.summary.MaxDate)
              })
            ],
            minDate: this.planningEntryService.summary.MinDate,
            maxDate: this.planningEntryService.summary.MaxDate,
            infoText: ''
          }),
          new ApDynformsConfigTextbox({
            key: 'Title',
            label: 'Global__Note',
            value: this.planningEntryService.summary.Title,
          })
        ],
      });
    }
  }

  private _productFieldSet(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'Global__Product',
      legend: 'Global__Product',
      config: [
        new ApDynformsConfigComboBox({
          key: 'Group',
          label: 'Global__Lbl_Group',
          options: [
            {key: ApNutrientGroup.Mineral, text: 'Nutrients_Pages_Popups__Inorganic'},
            {key: ApNutrientGroup.Organic, text: 'Nutrients_Pages_Popups__Organic'}
          ],
          textField: 'text',
          valueField: 'key',
          translate: true,
          value: this.planningEntryService.summary.Group_Id,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            }),
          ],
          valuePrimitive: true
        }),
        new ApDynformsConfigComboBox({
          key: 'Product_Id',
          label: 'Global__Product',
          valueField: 'Id',
          textField: 'Prodname',
          value: this.fertilizerStore.getFertilizer(this.planningEntryService.summary.Product_Id),
          options: combineLatest([
            this._changedProductDropdownContent.asObservable(),
            this.fertilizerStore.OrganicFertilizer$,
            this.fertilizerStore.InorganicFertilizer$
          ]).pipe(map(([_, organic, inorganic]) => {
            switch (this.planningEntryService.summary.Group_Id) {
              case ApNutrientGroup.Organic:
                return this._getFertilizerWithContent(this.planningEntryService.summary, organic);
              case ApNutrientGroup.Mineral:
              default:
                return this._getFertilizerWithContent(this.planningEntryService.summary, inorganic);
            }
          })),
          translate: true,
          validators: [
            new ApDynformsValidator({
              validator: Validators.min(1),
              errorKey: 'Settings__Msg_Vali_Value_Required',
              always: true
            }),
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required',
              always: true
            })
          ],
          valuePrimitive: false
        }),
      ],
    });
  }

  private _agronomyFieldSet(): ApDynformsConfigFieldset {
    if (this.planningEntryService.summary.User_Operation_Mode === ApOperationMode.AppMap) {
      return new ApDynformsConfigFieldset({
        key: 'Global__Agronomy',
        legend: 'Global__Agronomy',
        config: [
          new ApDynformsConfigNumerictextbox({
            key: 'Amount_Percent',
            label: 'Nutrients__Percentage_Remaining_Needs_2_%',
            value: this.planningEntryService.summary.Amount_Percent,
            infoText: 'Global_Percentage_Amount_Remaining_Needs_Applied_Field',
            formErrors: ['Settings__Msg_Vali_Value_Required'],
            min: 0,
            max: 100,
            decimals: 1
          }),
          new ApDynformsConfigPlaceholder(),
          new ApDynformsConfigNumerictextbox({
            key: 'Amount_Nutrient',
            label$: this._nutrientUnitFertilizerLabel$,
            value: this._getNutrientAmountForAgronomyFieldSet(this.planningEntryService.summary.Element,
              this.planningEntryService.summary.Amount_Nutrient),
            infoText: 'Info_Uniform_BF',
            formErrors: ['Settings__Msg_Vali_Value_Required'],
            min: 0,
            decimals: this._settings?.DigitsAfterDecimalPoint ?? 0
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'Amount_Goods',
            label$: this._amountUnitFertilizerLabel$,
            value: this.planningEntryService.summary.Amount_Goods,
            infoText: 'Global_Average_Amount_Quantity_Applied_Field',
            formErrors: ['Settings__Msg_Vali_Value_Required', 'Text_ValueInvalid'],
            min: 0,
            decimals: this._settings?.DigitsAfterDecimalPoint ?? 0
          })
        ],
      });
    } else {
      return new ApDynformsConfigFieldset({
        key: 'Global__Agronomy',
        legend: 'Global__Agronomy',
        config: [
          new ApDynformsConfigNumerictextbox({
            key: 'TotalSum',
            label$: this._totalSumUnitFertilizerLabel$,
            value: this.planningEntryService.getTotalSumValueFromConst(this.planningEntryService.summary.Amount_Goods,
              this.planningEntryService.summary.TotalFieldsArea),
            formErrors: ['Settings__Msg_Vali_Value_Required'],
            min: 0,
            decimals: this._settings.DigitsAfterDecimalPoint
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'Amount_Goods',
            label$: this._amountUnitFertilizerLabel$,
            value: this.planningEntryService.summary.Amount_Goods,
            infoText: 'Global_Average_Amount_Quantity_Applied_Field',
            formErrors: ['Settings__Msg_Vali_Value_Required'],
            min: 0,
            decimals: this._settings.DigitsAfterDecimalPoint
          }),
        ],
      });
    }
  }

  private _minMaxConstFieldSet(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'Min_Max_Const',
      legend: 'Global__Planning_Min_Max_Const',
      config: [
        new ApDynformsConfigNumerictextbox({
          key: 'User_Appl_Min',
          label$: this._minimumUnitFertilizerLabel$,
          value: this.planningEntryService.summary.User_Appl_Min,
          min: 0,
          step: 1,
          decimals: 1,
          formErrors: ['Text_ValueInvalid'],
          infoText: 'Global_Minimum_Goods_Not_Fallen_Short_All_Fields'
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'User_Appl_Max',
          label$: this._maximumUnitFertilizerLabel$,
          value: this.planningEntryService.summary.User_Appl_Max,
          min: 0,
          step: 1,
          decimals: 1,
          formErrors: ['Text_ValueInvalid'],
          infoText: 'Global_Maximum_Goods_Not_Exceeded_All_Fields'
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'User_Appl_Const',
          label$: this._constantUnitFertilizerLabel$,
          value: this.planningEntryService.summary.User_Appl_Const,
          infoText: 'Global_Constant_Amount_Applied_Field_Absence_Gps',
          decimals: 1,
          min: 0,
          disabled$: this._isConstFieldDisabled.asObservable(),
          formErrors: ['Text_ValueInvalid'],
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required',
              always: true
            })
          ]
        }),
        new ApDynformsConfigPlaceholder()
      ],
    });
  }

  private _documentFiledSet(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'Docu',
      legend: 'Global__Documentation',
      config: [
        new ApDynformsConfigComboBox({
          key: 'Machine',
          label: 'Settings__Lbl_Menu_Devices',
          textField: 'NameWithSign',
          valueField: 'Id',
          valuePrimitive: false,
          value: this.planningEntryService.summary.Machine,
          options: this.machineStore.Listen(_ => _.data),
          sort: 1
        }),
        new ApDynformsConfigComboBox({
          key: 'Instrument',
          label: 'Docu_Ini__Tool',
          textField: 'Name',
          valueField: 'Id',
          valuePrimitive: false,
          value: this.planningEntryService.summary.Instrument,
          options: this.instrumentStore.Listen(s => s.data),
          sort: 1
        }),
        new ApDynformsConfigComboBox({
          key: 'Driver',
          label: 'Docu_Ini__Motorist',
          textField: 'ShortFullName',
          valueField: 'Id',
          valuePrimitive: false,
          value: this.planningEntryService.summary.Driver,
          options: this.driverStore.Listen(_ => _.data),
          sort: 1
        })
      ]
    });
  }

  //#endregion "Field Sets"

  //#region "Form details changes"

  private _onDetailsFormValueChanges(control: string, value: any): void {
    if (!control || this._isLock) {
      return;
    }
    const inputsToUpdate: { [key: string]: any } = {};
    if (control === 'User_Appl_Date') {
      this.planningEntryService.summary.User_Appl_Date = value as Date;
    }
    if (control === 'User_Operation_Mode') {
      this.planningEntryService.summary.User_Operation_Mode = value;
      // Konstant: Default Produktgruppe organisch wählen - insofern die Gruppe noch auf dem alten Default 0 steht
      if (this.planningEntryService.summary.User_Operation_Mode === ApOperationMode.Const) {
        if (this.planningEntryService.summary.Group_Id === 0) {
          this.planningEntryService.summary.Group_Id = ApNutrientGroup.Organic;
        }
        this.planningEntryService.summary.User_Appl_Mode = ApApplicationMode.Constant;
        // Leitnährstoff aus event. bereits gewähltem Produkt ermitteln
        // ggf. auch die Warenberechnung erneut durchführen, da bei Konstant die Ware nicht erneut berechnet wird
        this.planningEntryService.summary.Element =
          this.nutrientManagementService.getFertilizerLeadingElement(this.planningEntryService.summary.Product_Id)
          ?? ApElementType.P;
      } else {
        this.planningEntryService.summary.Element = ApElementType.P;
        this.planningEntryService.summary.User_Appl_Mode = ApApplicationMode.Variable;
      }
      this._cleanUpProductInput(inputsToUpdate);
      this._cleanUpAmountInputs(inputsToUpdate);
      this._setDetailsFormValues(inputsToUpdate);
      this.fieldSetsUpdate$.next(this.getDetailFieldsetControls());
    }
    if (control === 'Element') {
      this.planningEntryService.summary.Element = value;
      this._cleanUpProductInput(inputsToUpdate);
      this._cleanUpAmountInputs(inputsToUpdate);
      this._setDetailsFormValues(inputsToUpdate);
      this._changedProductDropdownContent.next(0);
      this._setConstInputFieldAvailability();
    }
    if (control === 'Title') {
      this.planningEntryService.summary.Title = value;
    }
    if (control === 'Group') {
      if (this.planningEntryService.summary.Group_Id === value) {
        this._setDetailsFormValues(inputsToUpdate);
        return;
      }
      this.planningEntryService.summary.Group_Id = value;
      // Reinnährstoff
      if (value === 0) {
        inputsToUpdate['Product_Id'] = null;
        this.planningEntryService.summary.Product_Id = 0;
        this._setFormLabels(0);
      } else {
        this._cleanUpProductInput(inputsToUpdate);
      }
      this._cleanUpAmountInputs(inputsToUpdate);
      this._setDetailsFormValues(inputsToUpdate);
      this._changedProductDropdownContent.next(0);
    }
    if (control === 'Product_Id') {
      this.planningEntryService.summary.Product_Id = value?.Id ?? 0;
      this._setFormLabels(this.planningEntryService.summary.Product_Id);
      if (this.planningEntryService.summary.Product_Id > 0) {
        if (this.planningEntryService.summary?.Amount_Nutrient > 0) {
          this.planningEntryService.summary.User_Appl_Unit = this.planningEntryService.getM3HaOrTHaOrKgHaUnitIdForOrganic(
            this.planningEntryService.summary.Group_Id,
            this.planningEntryService.summary.Product_Id);
        } else {
          this.planningEntryService.summary.User_Appl_Unit = this.planningEntryService.globalUnitPercent.Id;
        }
        if (this.planningEntryService.summary.User_Operation_Mode === ApOperationMode.Const) {
          // für const kann der Nutzer das Element nicht wählen → anhand von Leitnährstoff automatisch bestimmen
          this.planningEntryService.summary.Element =
            this.nutrientManagementService.getFertilizerLeadingElement(this.planningEntryService.summary.Product_Id)
            ?? ApElementType.P;
        }
      }
      this._cleanUpAmountInputs(inputsToUpdate);
      this._setDetailsFormValues(inputsToUpdate);
    }
    if (control === 'User_Appl_Const') {
      this.planningEntryService.summary.User_Appl_Const = value;
    }
    if (control === 'User_Appl_Min') {
      this.planningEntryService.summary.User_Appl_Min = value;
    }
    if (control === 'User_Appl_Max') {
      this.planningEntryService.summary.User_Appl_Max = value;
    }
    if (control === 'Amount_Nutrient') {
      this.planningEntryService.summary.Amount_Percent = null;
      this.planningEntryService.summary.User_Appl_Unit = this.planningEntryService.getM3HaOrTHaOrKgHaUnitIdForOrganic(
        this.planningEntryService.summary.Group_Id,
        this.planningEntryService.summary.Product_Id);
      const element: number = this._getElement_WorkaroundForSummariesOrPlanBooks(
        this.planningEntryService.summary);
      this.planningEntryService.summary.Amount_Nutrient = this.elementService.CalculateToElementByGivenValue(
        value, element, this._settings.IsOxidFarm, this._settings.IsOxidFarm);
      this.planningEntryService.summary.Amount_Goods = this.nutrientService.convertNutrientToGoods(
        this.planningEntryService.summary.Product_Id, element,
        this.planningEntryService.summary.Amount_Nutrient);
      inputsToUpdate['Amount_Goods'] = this.planningEntryService.summary.Amount_Goods;
      inputsToUpdate['Amount_Nutrient'] = value;
      inputsToUpdate['Amount_Percent'] = null;
      this._setDetailsFormValues(inputsToUpdate);
    }
    if (control === 'Amount_Goods' || control === 'TotalSum') {
      let totalSum = 0;
      let goodsAmount = 0;
      switch (control) {
        case 'Amount_Goods':
          totalSum = this.planningEntryService.getTotalSumValueFromConst(value,
            this.planningEntryService.summary.TotalFieldsArea);
          goodsAmount = value;
          break;
        case 'TotalSum':
          totalSum = value;
          goodsAmount = this.planningEntryService.getConstValueFromTotalSum(value,
            this.planningEntryService.summary.TotalFieldsArea);
          break;
      }
      this.planningEntryService.summary.Amount_Percent = null;
      this.planningEntryService.summary.User_Appl_Unit = this.planningEntryService.getM3HaOrTHaOrKgHaUnitIdForOrganic(
        this.planningEntryService.summary.Group_Id,
        this.planningEntryService.summary.Product_Id);
      const element: number = this._getElement_WorkaroundForSummariesOrPlanBooks(
        this.planningEntryService.summary);
      this.planningEntryService.summary.Amount_Goods = goodsAmount;
      this.planningEntryService.summary.Amount_Nutrient = this.nutrientService.convertGoodsToNutrient(
        this.planningEntryService.summary.Product_Id,
        element,
        goodsAmount,
        false);
      inputsToUpdate['TotalSum'] = totalSum;
      inputsToUpdate['Amount_Goods'] = this.planningEntryService.summary.Amount_Goods;
      inputsToUpdate['Amount_Nutrient'] = this.planningEntryService.summary.Amount_Nutrient;
      inputsToUpdate['Amount_Percent'] = this.planningEntryService.summary.Amount_Percent;
      this._setDetailsFormValues(inputsToUpdate);
    }
    if (control === 'Amount_Percent') {
      this.planningEntryService.summary.Amount_Nutrient = null;
      this.planningEntryService.summary.Amount_Goods = null;
      this.planningEntryService.summary.Amount_Percent = value;
      this.planningEntryService.summary.User_Appl_Unit = this.planningEntryService.globalUnitPercent.Id;
      inputsToUpdate['Amount_Nutrient'] = null;
      inputsToUpdate['Amount_Goods'] = null;
      inputsToUpdate['TotalSum'] = null;
      inputsToUpdate['Amount_Percent'] = value;
      this._setDetailsFormValues(inputsToUpdate);
    }
    if (control === 'Machine') {
      this.planningEntryService.summary.Machine = value;
      const formValues = this._dynComponent.dynForm.form.getRawValue();
      if (!formValues['Driver'] && value?.DefaultDriver) {
        inputsToUpdate['Driver'] = this.planningEntryService.summary.Driver = value.DefaultDriver;
      }
      if (!formValues['Instrument'] && value?.DefaultInstrument) {
        inputsToUpdate['Instrument'] = this.planningEntryService.summary.Instrument = value.DefaultInstrument;
      }
      this._setDetailsFormValues(inputsToUpdate);
    }
    if (control === 'Driver') {
      this.planningEntryService.summary.Driver = value;
    }
    if (control === 'Instrument') {
      this.planningEntryService.summary.Instrument = value;
    }
  }

  private _setDetailsFormValues(value: { [p: string]: any }): void {
    if (this._isLock) {
      return;
    }
    this._isLock = true;
    if (this._dynComponent.dynForm === undefined) {
      Object.keys(value).forEach(val => {
          const c = this._dynComponent.dynForm.form.get(val);
          if (c !== null && c.value !== value[val]) {
            c.setValue(value[val]);
          }
        }
      );
      setTimeout(() => this._isLock = false, 1);
      return;
    }
    Object.keys(value).forEach(val => {
        const c = this._dynComponent.dynForm.form.get(val);
        if (c !== null && c.value !== value[val]) {
          c.setValue(value[val]);
        }
      }
    );
    setTimeout(() => this._isLock = false, 1);
  }

  private _cleanUpProductInput(value: any): void {
    value['Product_Id'] = null;
    this.planningEntryService.summary.Product_Id = null;
  }

  private _cleanUpAmountInputs(value: any): void {
    value['Amount_Goods'] = null;
    value['TotalSum'] = null;
    value['Amount_Nutrient'] = null;
    value['Amount_Percent'] = null;
    value['User_Appl_Max'] = null;
    value['User_Appl_Min'] = null;
    value['User_Appl_Const'] = null;
    this.planningEntryService.summary.Amount_Goods = null;
    this.planningEntryService.summary.Amount_Nutrient = null;
    this.planningEntryService.summary.Amount_Percent = null;
    this.planningEntryService.summary.User_Appl_Max = null;
    this.planningEntryService.summary.User_Appl_Min = null;
    this.planningEntryService.summary.User_Appl_Const = null;
  }

  //#endregion "Form details changes"

  //#region "Private methods"

  // Workaround for summaries/plan books with the wrong element (might be due to migration from 4.8)
  private _getElement_WorkaroundForSummariesOrPlanBooks(summary: INutrientPlaningSummaryData): number {
    return summary.User_Operation_Mode === ApOperationMode.Const
      ? this.nutrientService.getFertilizerLeadingElement(summary.Product_Id)
      : summary.Element;
  }

  private _getNutrientAmountForAgronomyFieldSet(element: ApElementType, nutrientAmount: number): number | null {
    if (!this._settings.IsOxidFarm) {
      return nutrientAmount;
    }
    return this.elementService.CalculateToElementByGivenValue(nutrientAmount, element, false, true);
  }

  private _getFertilizerWithContent(summary: INutrientPlaningSummaryData, fertilizers: CombinedFertilizer[]): CombinedFertilizer[] {
    const fertList: CombinedFertilizer[] = [];
    // If constant → show all fertilizers (not restricted to element)
    if (summary?.User_Operation_Mode === ApOperationMode.Const) {
      fertilizers.forEach((i: any): void => {
        if (i.ContentP !== 0 || i.ContentK !== 0 || i.ContentMg !== 0 || i.ContentCaO !== 0) {
          fertList.Add(i);
        }
      });
    } else {
      const element = summary?.Element;
      fertilizers.forEach((i: any): void => {
        switch (true) {
          case (element === 1 && i.ContentP > 0):
          case (element === 2 && i.ContentK > 0):
          case (element === 3 && i.ContentMg > 0):
          case (element === 4 && i.ContentCaO > 0):
          case (element === 5 && i.ContentN > 0):
            fertList.Add(i);
            break;
        }
      });
    }
    return fertList.sort((first, second) => {
      if (first.Prodname == null) {
        return 1;
      }
      if (second.Prodname == null) {
        return -1;
      }
      return first.Prodname.localeCompare(second.Prodname);
    });
  }

  private _setConstInputFieldAvailability(): void {
    const applicationModes = this.planningEntryService.summary.Selected_Fields.map(selectedField => {
      const modesAndStatusForPlan = this.planningEntryService.getModesAndStatusForPlan(selectedField,
        this.planningEntryService.summary.Element);
      return modesAndStatusForPlan.ApplicationMode;
    });
    const isAllFieldsVariable = applicationModes.every(x => x === ApApplicationMode.Variable);
    if (this._isConstFieldDisabled.getValue() !== isAllFieldsVariable) {
      this._isConstFieldDisabled.next(isAllFieldsVariable);
    }
  }

  private _setFormLabels(productId: number): void {
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    this._amountUnitFertilizerLabel$.next(this.unitService.getUnitWithLabelKey(fertilizer?.Unit, 'Nutrients__Average_Amount_Goods'));
    this._constantUnitFertilizerLabel$.next(this.unitService.getUnitWithLabelKey(fertilizer?.Unit, 'Global__Const_Goods'));
    this._totalSumUnitFertilizerLabel$.next(this.unitService.getUnitWithLabelKey(fertilizer?.Unit, 'Global__Sum_Goods', false));
    this._minimumUnitFertilizerLabel$.next(this.unitService.getUnitWithLabelKey(fertilizer?.Unit, 'Global__Minimum_Goods'));
    this._maximumUnitFertilizerLabel$.next(this.unitService.getUnitWithLabelKey(fertilizer?.Unit, 'Global__Maximum_Goods'));
  }

  private _getValuesForTypeDropDown(): { OperationModeId: number, ApplicationModeKey: string }[] {
    const operationModes = this.operationModesStore.OperationModes;
    const operationModeConst = operationModes.find(x => x.Key === OperationModesEnum.Constant);
    const operationModeApplicationMap = operationModes.find(x => x.Key === OperationModesEnum.ApplicationMap);
    return [
      {
        OperationModeId: operationModeConst.Id,
        // TODO: temporary work around because of changes for ApplicationMap in N-Fertilization module
        ApplicationModeKey: ApplicationModesEnum.Constant
      },
      {
        OperationModeId: operationModeApplicationMap.Id,
        // TODO: temporary work around because of changes for ApplicationMap in N-Fertilization module
        ApplicationModeKey: ApplicationModesEnum.Variable
      }
    ];
  }

  //#endregion "Private methods"
}
