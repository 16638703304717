import {NgModule}                                 from '@angular/core';
import {CommonModule}                             from '@angular/common';
import {ApNFertilizationPlanningEntryComponent}   from './ap-n-fertilization-planning-entry.component';
import {ApDynComponentModule}                     from '../../ap-dyncomponent/ap-dyncomponent.module';
import {ApStatisticsModule}                       from '../../statistics/ap-statistics.module';
import {ApResponsiveTextModule}                   from '../../ap-responsive-text/ap-responsive-text.module';
import {ApCoreModule}                             from '../../ap-core/ap-core.module';
import {ButtonModule}                             from '@progress/kendo-angular-buttons';
import {ApNFertilizationBookingComponent}         from './ap-n-fertilization-booking.component';
import {ApNFertilizationEditComponent}            from './ap-n-fertilization-edit.component';
import {ApNFertilizationLogfileEditComponent}     from './ap-n-fertilization-logfile-edit.component';
import {ApNFertilizationShowComponent}            from './ap-n-fertilization-show.component';
import {ApTranslationModule}                      from '../../translation/ap-translation.module';
import {NFertilizationApplicationSettingsService} from './services/n-fertilization-application-settings.service';
import {NFertilizationCommonEntryService}         from './services/n-fertilization-common-entry.service';
import {NFertilizationPlanningEntryService}       from './services/n-fertilization-planning-entry.service';

@NgModule({
  declarations: [
    ApNFertilizationPlanningEntryComponent,
    ApNFertilizationBookingComponent,
    ApNFertilizationEditComponent,
    ApNFertilizationLogfileEditComponent,
    ApNFertilizationShowComponent
  ],
  imports: [
    CommonModule,
    ApDynComponentModule,
    ApStatisticsModule,
    ApResponsiveTextModule,
    ApCoreModule,
    ButtonModule,
    ApTranslationModule
  ],
  providers: [
    NFertilizationCommonEntryService,
    NFertilizationPlanningEntryService,
    NFertilizationApplicationSettingsService
  ]
})
export class ApNFertilizationEntryComponentsModule {
}
