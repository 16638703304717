import {AfterContentInit, Component, ViewEncapsulation} from '@angular/core';
import {Router}                                         from '@angular/router';
import {ApMenuUrls}                                     from '../../ap-interface';
import {ApPermissions}                                  from 'invoker-transport';
import {AccessPermissionsStore}                         from '../../stores/settings/access.permission.store';
import {LoginStore}                                     from '../../stores/login/login.store';
import {TranslationStore}                               from '../../stores/translation/translation.store';
import {GetPermissionPipe}                              from '../../ap-permission/pipes/get-permission.pipe';
import {ApWindowHelperService}                          from '../../ap-core/services/ap-window-helper.service';
import {APP_CONFIGURATION}                              from '../../ap-core/config';
import IUser = Data.Authentication.IUser;

export interface IUserProfile {
  id: string;
  text: string;
  data_cy: string;
}

@Component({
  selector: 'ap-user-profile',
  templateUrl: 'ap-user-profile.component.html',
  styleUrls: ['ap-user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApUserProfileComponent implements AfterContentInit {
  public user: IUser;
  public editProfile = '';
  public logout = '';
  public academy = '';
  public teamViewer = '';
  public legal = '';
  public userProfile: Array<IUserProfile>;
  public ApPermissions = ApPermissions;
  public popupSettings = {popupClass: 'user-profile-buttons'};

  constructor(private loginStore: LoginStore,
              private router: Router,
              private translationStore: TranslationStore,
              private permissionsStore: AccessPermissionsStore,
              private permissionPipe: GetPermissionPipe,
              private windowHelper: ApWindowHelperService) {
    this.user = this.loginStore.User;
    this.editProfile = this.translationStore.FindTranslationForSelectedLanguage('Global_User_Profile');
    this.logout = this.translationStore.FindTranslationForSelectedLanguage('Base__Logout');
    this.academy = this.translationStore.FindTranslationForSelectedLanguage('Permission_FeatureModul_Academy');
    this.teamViewer = this.translationStore.FindTranslationForSelectedLanguage('Global__Remote_Maintenance');
    this.legal = this.translationStore.FindTranslationForSelectedLanguage('Lbl_ImprintText');
    this.permissionsStore.Listen(s => s.data).subscribe(() => this.afterChangePermission());
  }

  ngAfterContentInit(): void {
    this.fillDropDown();
  }

  private afterChangePermission(): void {
    this.fillDropDown();
  }

  public onItemClicked(e: any): void {
    switch (e.id) {
      case '[data-cy=ap_user_profile_logout_button]':
        this.loginStore.logoutUser();
        break;
      case '[data-cy=ap_user_profile_edit_profile]':
        this.router.navigate([ApMenuUrls.SETTINGS_USER_PROFILE]).then();
        break;
      case '[data-cy=ap_user_profile_academy_button]':
        this.windowHelper.openSite(APP_CONFIGURATION.AcademyUrl);
        break;
      case '[data-cy=ap_user_profile_teamviewer_button]':
        this.windowHelper.openSite(APP_CONFIGURATION.TeamViewerUrl);
        break;
      case '[data-cy=ap_user_profile_legal_button]':
        const fullPathToLegalInfo = `${this.windowHelper.getLocationOrigin()}/${ApMenuUrls.INFO_LEGAL}`;
        this.windowHelper.openSite(fullPathToLegalInfo, '_blank');
        break;
    }
  }

  private fillDropDown(): void {
    this.permissionPipe.transform(ApPermissions.EDIT_USER_PROFILE).subscribe(permission => {
      this.userProfile = [{
        id: 'user_profile_header_dummy',
        text: this.user?.EMail,
        data_cy: ''
      }];
      if (permission) {
        this.userProfile.push({
          id: '[data-cy=ap_user_profile_edit_profile]',
          text: this.editProfile,
          data_cy: '[ap_user_profile_edit_profile]'
        });
      }
      this.userProfile.push({
        id: '[data-cy=ap_user_profile_academy_button]',
        text: this.academy,
        data_cy: '[ap_user_profile_academy_button]'
      });
      this.userProfile.push({
        id: '[data-cy=ap_user_profile_teamviewer_button]',
        text: this.teamViewer,
        data_cy: '[ap_user_profile_teamviewer_button]'
      });
      this.userProfile.push({
        id: '[data-cy=ap_user_profile_legal_button]',
        text: this.legal,
        data_cy: '[ap_user_profile_legal_button]'
      });
      this.userProfile.push({
        id: '[data-cy=ap_user_profile_logout_button]',
        text: this.logout,
        data_cy: '[ap_user_profile_logout_button]'
      });
    });
  }
}
