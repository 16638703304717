import {IStateStore}         from '../../ap-interface';
import {Injectable}          from '@angular/core';
import {Store}               from '../index';
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';
import {
  MonitoringRulesCalculateRecommendationResult,
  MonitoringRulesCalculateRecommendation,
  NetTypes
}                            from 'invoker-transport';
import IMonitoringRules = Data.Agronomic.IMonitoringRules;
import IResultMessage = Invoker.IResultMessage;
interface IMonitoringRulesStore extends IStateStore<IMonitoringRules> {
}

/**
 * Store for handling monitoring rules and calculating recommendation values
 */
@Injectable({providedIn: 'root'})
export class MonitoringRulesStore extends Store<IMonitoringRulesStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: true,
      loading: false,
      data: []
    });
  }

  /**
   * Calculates a recommended value based on the given monitoring data
   * This watches the backend response stream to immediately return a promise which can be resolved by client component
   * @param measuredValue the value measured by customer
   * @param eppoCode the field's current crop's eppoCode
   * @param ecStage the monitoring growth stage
   * @param monitoringTypeId the monitoring type (e.g. n-tester, nitrate check etc.)
   */
  public calculateRecommendation(measuredValue: number, eppoCode: string, ecStage: number, monitoringTypeId: number): Promise<number> {
    return new Promise((res, rej) => {
      this.DispatchBackend(new MonitoringRulesCalculateRecommendation([
        {Name: 'measuredValue', Type: NetTypes.FLOAT, Value: measuredValue},
        {Name: 'eppoCode', Type: NetTypes.STRING, Value: eppoCode},
        {Name: 'ecStage', Type: NetTypes.INT, Value: ecStage},
        {Name: 'monitoringTypeId', Type: NetTypes.INT, Value: monitoringTypeId}
      ])).watchStream({
        action: MonitoringRulesCalculateRecommendationResult,
        todo: (msg: IResultMessage) => {
          if (msg.Error) {
            rej(msg.Error);
            return;
          }
          res(msg.Data);
        }
      });
    });
  }
}
