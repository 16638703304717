import {Injectable}                                                    from '@angular/core';
import {INutrientPlanningEntryStepsConfig, PlanningEntryComponentData} from './nutrient-planning-types';
import {
  ApDynComponentComponent
}                                                                      from 'src/app/ap-dyncomponent/ap-dyncomponent.component';
import {
  NPlanningEntryComponentData
}                                                                      from '../../ap-n-fertilization-entry-components/types/n-fertilization-planning-entry.types';
import IField = Data.FieldManagement.IField;

@Injectable()
export class BasePlanningEntryService implements INutrientPlanningEntryStepsConfig {
  protected _isSaved: boolean | undefined;
  protected _isNewMode: boolean | undefined;
  protected _shouldDeselectFieldsOnClose: boolean | undefined;
  protected _selectedFields: IField[] = [];
  protected _componentData: PlanningEntryComponentData | NPlanningEntryComponentData;

  public initData(): void {
    this._shouldDeselectFieldsOnClose = false;
    this._isNewMode = false;
    this._isSaved = false;
  }

  public get getSelectedFields(): IField[] {
    return this._selectedFields;
  }

  public setComponentData(data: PlanningEntryComponentData | NPlanningEntryComponentData): void {
    this._componentData = data;
  }

  public setSubscriptions(dynComponent: ApDynComponentComponent): void {
  }

  public clearData(): void {
  }
}
