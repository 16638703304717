import {Injectable}                                      from '@angular/core';
import {combineLatest, Observable}                       from 'rxjs';
import {filter, map}                                     from 'rxjs/operators';
import {ApFeatureModulKey}                               from '../../ap-interface/enums/ap-feature-modul-key.enum';
import {Create}                                          from 'ts-tooling';
import {AgriportConstantsEnum, BaseFertLicenseAmountAbs} from '../../ap-interface/enums/ap-agriport-constants.enum';
import {
  IModalDialogResult
}                                                        from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {ModalDialogButtonKeys, ModalDialogPresets}       from '../../stores/dialog/modal.dialog.presets';
import {SoilSampleDateStore}                             from '../../stores/nutrients/soilsampledate.store';
import {SoilSampleDateService}                           from './soil-sample-date.service';
import {CampaignYearStore}                               from '../../stores/login/campaignyear.store';
import {FieldStore}                                      from '../../stores/farm/field.store';
import {CampaignYearService}                             from './campaign-year.service';
import {AgriportConstantsService}                        from '../common/agriport-constants.service';
import {TranslationStore}                                from '../../stores/translation/translation.store';
import {GetRoundNumericService}                          from '../../ap-utils/service/get-round-numeric.service';
import {LicenseStore}                                    from '../../stores/farm/license.store';
import {ModalDialogStore}                                from '../../stores/dialog/modal.dialog.store';
import ILicense = Data.Licensing.ILicense;
import IFarm = Data.Authentication.IFarm;
import IField = Data.FieldManagement.IField;
import ICampaignYear = Data.Authentication.ICampaignYear;
import LicenseAdjustAction = Data.Licensing.Enums.LicenseAdjustAction;

@Injectable({providedIn: 'root'})
export class LicenseService {
  constructor(private fieldStore: FieldStore,
              private modalDialogStore: ModalDialogStore,
              private translationStore: TranslationStore,
              private campaignYearStore: CampaignYearStore,
              private soilSampleDateStore: SoilSampleDateStore,
              private roundService: GetRoundNumericService,
              private campaignYearService: CampaignYearService,
              private soilSampleDatesService: SoilSampleDateService,
              private agriportConstantsService: AgriportConstantsService,
              private modalDialogPresets: ModalDialogPresets) {
  }

  public InvokeBaseFertilisationLicenseAdjustmentLogic(licenseStore: LicenseStore,
                                                       baseDataLoaded$: Observable<any[]>,
                                                       selectedFarm$: Observable<IFarm>): void {
    // Here we show modal window about license information with an ability to extend it.
    // It shows in case when the amount of area for current campaign year is > or < 10 % from the licensed base fertilisation modul feature area
    let isModalDialogShowed = false;
    const licenseCheckSubscription = combineLatest([
      baseDataLoaded$,
      licenseStore.licenseData$,
      this.fieldStore.Fields$,
      this.campaignYearStore.SelectedCampaignYear$,
      this.soilSampleDateStore.Dates$,
      selectedFarm$
    ]).pipe(
      filter(([loadedData, licenses, fields, selectedCampaignYear, _, selectedFarm]) => {
        if (loadedData.some(x => !x) || !selectedFarm || !selectedCampaignYear || !licenses || !fields) {
          return false;
        }
        const campaignYearRange = this.campaignYearService.getCampaignYearByDate(new Date());
        const selectedYearRange = this.campaignYearService.getCampaignYearRange(selectedCampaignYear.Year);
        return campaignYearRange.StartYear < campaignYearRange.EndYear
          && selectedYearRange.StartYear < campaignYearRange.EndYear
          && this.campaignYearService.isCampaignYearIntersects(campaignYearRange.StartYear, campaignYearRange.EndYear, selectedYearRange);
      }),
      map(([, licenses, fields, selectedCampaignYear, soilSampleDates, selectedFarm]) => {
        const fieldsWithValidSoilSample = this.soilSampleDatesService.getFieldsWithValidSoilSampleDate(fields, soilSampleDates)
          .filter(x => x.FarmId === selectedFarm.Id);
        const selectedYearRange = this.campaignYearService.getCampaignYearRange(selectedCampaignYear.Year);
        const license = licenses.filter(x => x.FeatureModul.Key === ApFeatureModulKey.BaseFertilization && x.FarmId === selectedFarm.Id && !x.TrialExpireAt)
          .find(x => this.campaignYearService.isCampaignYearIntersects(new Date(x.ValidFrom), new Date(x.ValidTo), selectedYearRange));
        return ([license, fieldsWithValidSoilSample, selectedCampaignYear] as [ILicense, IField[], ICampaignYear]);
      }),
      filter(([license, fields, selectedCampaignYear]) => {
        return fields.length > 0
          && license && license.QuantityErp && !license.NoCheck
          && selectedCampaignYear.DefaultStart < selectedCampaignYear.DefaultEnd;
      })
    ).subscribe(([license, fields, selectedCampaignYear]) => {
      const fieldsAreaHaSum = fields.map(x => this.fieldStore.getFieldGeom(x, selectedCampaignYear))
        .reduce((total, current) => total + Create(current?.AreaHa, 0), 0);
      let quantityForConditionActivation = 0;
      const licenseComparisonType = this.agriportConstantsService.GetConstant(AgriportConstantsEnum.BaseFertLicenseAmountAbs);
      const licenseComparisonAmount = +this.agriportConstantsService.GetConstant(AgriportConstantsEnum.BaseFertLicenseAmountDiff);
      if (!isFinite(licenseComparisonAmount) || licenseComparisonAmount <= 0) {
        licenseCheckSubscription?.unsubscribe();
        isModalDialogShowed = false;
        return;
      }
      switch (licenseComparisonType) {
        case BaseFertLicenseAmountAbs.Hectare:
          quantityForConditionActivation = licenseComparisonAmount;
          break;
        case BaseFertLicenseAmountAbs.Percent:
          quantityForConditionActivation = fieldsAreaHaSum * licenseComparisonAmount / 100;
          break;
      }
      let modalWindowMessage = '';
      switch (true) {
        case fieldsAreaHaSum >= license.QuantityErp + quantityForConditionActivation:
          modalWindowMessage = this.translationStore.FindTranslationForSelectedLanguage('InfoText__Base_Fert_Feature_Area_Lower');
          break;
        default:
          licenseCheckSubscription?.unsubscribe();
          isModalDialogShowed = false;
          return;
      }
      const farmQuantityTranslation = this.translationStore.FindTranslationForSelectedLanguage('InfoText__Farm_Area_Quantity');
      const licenseQuantityTranslation = this.translationStore.FindTranslationForSelectedLanguage('InfoText__Licensed_Area_Quantity');
      modalWindowMessage += `\r\n\r\n${farmQuantityTranslation}: ${this.roundService.round(fieldsAreaHaSum)} ha` +
        `\r\n${licenseQuantityTranslation}: ${license.QuantityErp} ha`;
      if (!isModalDialogShowed) {
        isModalDialogShowed = true;
        this.modalDialogStore.setModalDialogData(this.modalDialogPresets.ExtendLicenseDialog({
          message: modalWindowMessage,
          resultDelegate: (dialogResult: IModalDialogResult): void => {
            let adjustAction = LicenseAdjustAction.Skip;
            if (dialogResult?.key === ModalDialogButtonKeys.Confirm) {
              adjustAction = LicenseAdjustAction.Adjust;
            }
            licenseStore.adjustLicenseRequest(ApFeatureModulKey.BaseFertilization, adjustAction);
            licenseCheckSubscription?.unsubscribe();
            isModalDialogShowed = false;
          }
        }));
      }
    });
  }
}
