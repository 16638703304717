import {ErrorHandler, Injectable, isDevMode} from '@angular/core';
import {ApErrorhandlerService}                             from './ap-errorhandler.service';
import {has}                                 from 'lodash';
import {NotifyStore}                         from '../../stores/dialog/notify.store';
import {LoginStore}                          from '../../stores/login/login.store';
import IResultMessage = Invoker.IResultMessage;

// TODO: introduce configuration to switch fullreport option.
const isFullReport = false;
const errorBucket: Array<any> = [];

@Injectable({providedIn: 'root'})
export class ApErrorHandler implements ErrorHandler {
  constructor(private loginStore: LoginStore,
              private errorHandlerService: ApErrorhandlerService,
              private notifyStore: NotifyStore) {

    errorHandlerService.confirmedError$.subscribe((data: IResultMessage) => {
      try {
        errorBucket.push({farmId: data.Data.FarmId, message: data.Data.Message});
      } catch (error) {
        console.log(error.message);
      }
    });
  }

  /***
   * Tries to determin the current farmId
   */
  tryGetFarmId(): number {
    try {
      return this.loginStore.SelectedFarm.Id;
    } catch {
      return 0;
    }
  }

  /**
   * Handles an exception/error which was either raised on client side
   * or passed from backend to client.
   */
  handleError(error): void {
    // use innerException if any.
    // this is needed in case of exception within promises.
    if (has(error, 'rejection')) {
      error = error.rejection;
    }

    const IS_VALIDATION_ERROR = has(error, 'ValidationResults');
    try {
      if (IS_VALIDATION_ERROR) {
        error.ValidationResults.forEach(note => {
          this.notifyStore.addMessage(note);
        });
      } else {
        this.loginStore.setClientErrorOccurred(true);
      }

      const meta = {
        Url: window?.location?.href,
        Browser: this._getBrowser(),
        UserAgent: window.navigator?.userAgent
      };
      const farmId = this.tryGetFarmId();
      // TODO: implement send full store data when wanted (use circular safe stringifier)
      const errorState = JSON.stringify(error);

      // whenever the error has not yet been confirmed(=logged by server) the error will be sent to the gateway.
      if (errorBucket.filter(e => e.farmId === farmId && e.message === error.message).length < 1) {
        this.errorHandlerService.LogClientError(error.message, farmId, error.stack, errorState, JSON.stringify(meta));
      }
    } catch (error) {
      // in case error cannot be sent to gateway we are logging this particular error to console, only.
      // otherwise we might create an infinite loop
    }

    if (isDevMode() && !IS_VALIDATION_ERROR) {
      throw error;
    }
  }

  private _getBrowser(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1 || agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1:
        return 'opera';
      case agent.indexOf('chrome') > -1:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
