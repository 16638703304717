import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef
}                                              from '@angular/core';
import {ILegend, ILegendSelectionItem}         from '../../../stores/map/map.legend.store';
import {ApLegendScrollerService}               from './ap-legend-scroller.service';
import {BehaviorSubject, combineLatest, merge} from 'rxjs';
import {distinctUntilChanged, filter}          from 'rxjs/operators';

export interface ISelectionChangeEvent {
  selectionKeys: string[];
  legend: ILegend;
}

@Component({
  selector: 'ap-legend-scroller-item',
  template: `
    <div class="ap-legend-scroller-item-wrapper">
      <div *ngIf="showPopup && item"
           class="ap-legend-scroller-item-popup">
        <div #popup>
          <div [style.height]="scrollerHeight$ | async">
            <ng-scrollbar [track]="'vertical'" [autoHeightDisabled]="false" [sensorDebounce]="100">
              <div class="ap-legend-values">
                <ap-legend-color-item
                  *ngFor="let v of item.values | getLayerValues:(currentSelectionKey | async); let i = index"
                  [color]="v.color" [title]="v.title" [index]="i"
                  [total]="(item.values | getLayerValues:(currentSelectionKey | async)).length">
                </ap-legend-color-item>
              </div>
            </ng-scrollbar>
          </div>
        </div>
        <div class="ap-legend-values-unit" *ngIf="item.unit">
          <ap-responsive-text>{{ item.unit | getUnitValue:(currentSelectionKey | async) }}</ap-responsive-text>
        </div>
        <div class="ap-legend-values-selection" *ngFor="let selection of item.selectionLists; let i = index">
          <kendo-combobox
            [data]="selection.values | getLegendSelectionValues:(currentSelectionKey | async) | translate_array:'text' | async"
            [textField]="'text'"
            [valueField]="'value'"
            [allowCustom]="false"
            [clearButton]="false"
            [disabled]="selection.disabled ?? false"
            [value]="selection.selectedValue | async"
            [popupSettings]="{appendTo: vcRef}"
            (valueChange)="selectValue($event, i)">
          </kendo-combobox>
        </div>
      </div>
      <button kendoButton
              *ngIf="item"
              class="ap-legend"
              (click)="toggle()">
        {{ item.title | translate }}
      </button>
    </div>
  `,
})
export class ApLegendScrollerItemComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  @ViewChild('popup', {read: ElementRef}) popup: ElementRef;
  @Input()
  public item: ILegend = null;
  public scrollerHeight$ = new EventEmitter<string>(true);

  public showPopup = true;
  public currentSelectionKey = new BehaviorSubject<string>('');

  constructor(private legendScrollerService: ApLegendScrollerService,
              public vcRef: ViewContainerRef) {
  }

  public ngOnDestroy(): void {
    if (!this.legendScrollerService.onSelectionKeyChange) {
      this.legendScrollerService.onSelectionKeyChange.unsubscribe();
    }
  }

  public ngAfterContentInit(): void {
    this.legendScrollerService.generateSelectionKey(this.item);
    this.legendScrollerService.onSelectionKeyChange
      .pipe(filter(d => d.l?.id === this.item.id))
      .subscribe(d => {
        this.currentSelectionKey.next(d.key);
        const keys = d.key.Split('_');
        for (let i = 0; i < Math.min(keys.length, this.item.selectionLists.length); i++) {
          const values = this.item.selectionLists[i].values;
          let item: ILegendSelectionItem;
          if (Array.isArray(values)) {
            item = values.Find(_ => _?.value === keys[i]);
          } else {
            item = values(...this.currentSelectionKey.getValue().Split('_')).Find(_ => _?.value === keys[i]);
          }
          if (item) {
            if (this.item.selectionLists[i].selectedValue != null &&
              this.item.selectionLists[i].selectedValue.getValue() != null &&
              this.item.selectionLists[i].selectedValue.getValue().value !== item.value) {
              this.item.selectionLists[i].selectedValue.next(item);
            }
          }
        }
      });

    combineLatest(this.item.selectionLists.map(selection => selection.selectedValue.pipe(distinctUntilChanged())))
      .subscribe(() => {
        this.legendScrollerService.generateSelectionKey(this.item);
        this.legendScrollerService.selectionChange.emit({
          selectionKeys: this.currentSelectionKey.getValue().Split('_'),
          legend: this.item,
        });
      });

    merge(
      combineLatest(this.item.selectionLists.map(selection => selection.selectedValue))
        .pipe(distinctUntilChanged(this._selectionListValueChanged)),
      combineLatest(this.item.subjects.map((subject) => subject))
        .pipe(distinctUntilChanged(this._subjectValueChanged))
    ).subscribe(() => {
      this.legendScrollerService.generateSelectionKey(this.item);
      this.legendScrollerService.selectionChange.emit({
        selectionKeys: this.currentSelectionKey.getValue().Split('_'),
        legend: this.item,
      });
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.scrollerHeight$.emit(`${this.popup?.nativeElement.clientHeight ?? 200}px`), 100);
  }

  public toggle(): void {
    this.showPopup = !this.showPopup;
  }

  public selectValue($event: ILegendSelectionItem, i: number): void {
    if (!Array.isArray(this.item.selectionLists)) {
      return;
    }
    let selectedItem: any;
    const values = this.item.selectionLists[i].values;
    if (Array.isArray(values)) {
      selectedItem = values.Find(_ => _?.value === $event?.value);
    } else {
      selectedItem = values(...this.currentSelectionKey.getValue().Split('_')).Find(_ => _?.value === $event?.value);
    }
    this.item.selectionLists[i].selectedValue.next(selectedItem);
  }

  private _selectionListValueChanged(prev: { value: any }[], next: { value: any }[]): boolean {
    return !!prev && !!next && prev.length === next.length && prev.every((v, i) => !!v && !!next[i] && v.value === next[i].value);
  }

  private _subjectValueChanged(prev: any[], next: any[]): boolean {
    return !!prev && !!next && prev.length === next.length && prev.every((v, i) => !!v && !!next[i] && v === next[i]);
  }
}
