import {IAction, IApInvokerArguments} from '../../interfaces';

enum MonitoringRulesActions {
  MONITORING_RULES_CALCULATE_RECOMMENDATION = '[monitoring-rules] calculate recommendation',
  MONITORING_RULES_CALCULATE_RECOMMENDATION_RESULT = '[monitoring-rules] calculate recommendation result'
}

export class MonitoringRulesCalculateRecommendation implements IAction {
  readonly type = MonitoringRulesActions.MONITORING_RULES_CALCULATE_RECOMMENDATION;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringRulesCalculateRecommendationResult implements IAction {
  readonly type = MonitoringRulesActions.MONITORING_RULES_CALCULATE_RECOMMENDATION_RESULT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}
